import React, { useMemo } from "react";
import { Icon } from "src/components/atoms/icons";
import TextInput from "src/components/atoms/text-input";
import {
  calculateTotalOneTimeFee,
  formatToTwoDecimalPoints,
  generateUUID,
} from "src/helpers";

const OneTimeFeeComponent = ({
  isEditMode,
  oneTimeCharges,
  setOneTimeCharges,
  defaultEditable,
  initialOneTimeCharges,
}: {
  isEditMode: any;
  chargesData: any;
  setChargesData: any;
  oneTimeCharges: any;
  setOneTimeCharges: any;
  defaultEditable: any;
  initialOneTimeCharges: any;
}) => {
  const handleValueChange = (categoryId: any, chargeId: any, value: any) => {
    setOneTimeCharges((prev: any) =>
      prev.map((category: any) =>
        category.id === categoryId
          ? {
              ...category,
              details: category.details.map((charge: any) =>
                charge.id === chargeId ? { ...charge, values: value } : charge,
              ),
            }
          : category,
      ),
    );
  };

  const handleChargeNameChange = (
    categoryId: any,
    chargeId: any,
    newName: any,
  ) => {
    setOneTimeCharges((prev: any) =>
      prev.map((category: any) =>
        category.id === categoryId
          ? {
              ...category,
              details: category.details.map((charge: any) =>
                charge.id === chargeId
                  ? { ...charge, name: newName, charge_description: newName }
                  : charge,
              ),
            }
          : category,
      ),
    );
  };

  const handleDeleteCharge = (categoryId: any, chargeId: any) => {
    setOneTimeCharges((prev: any) =>
      prev.map((category: any) =>
        category.id === categoryId
          ? {
              ...category,
              details: category.details.filter(
                (charge: any) => charge.id !== chargeId,
              ),
            }
          : category,
      ),
    );
  };

  const handleAddCharge = (categoryId: any) => {
    const newCharge = {
      id: generateUUID(),
      name: "",
      values: "",
    };

    setOneTimeCharges((prev: any) =>
      prev.map((category: any) =>
        category.id === categoryId
          ? { ...category, details: [...category.details, newCharge] }
          : category,
      ),
    );
  };

  const totalFee = useMemo(
    () => calculateTotalOneTimeFee(oneTimeCharges),
    [oneTimeCharges],
  );

  const totalFeeInitial = useMemo(
    () => calculateTotalOneTimeFee(initialOneTimeCharges),
    [initialOneTimeCharges],
  );

  return (
    <div
      className={`${totalFeeInitial == 0 && !defaultEditable ? "hidden" : "flex"} flex-col gap-x-8 border-b border-[#AFB6D1] pb-11`}
    >
      <div className="flex w-full justify-between py-10">
        <h3 className="text-16 font-medium text-primary-600">One-time fees</h3>
        <h3 className="text-18 font-bold text-primary-500-base">
          EUR {totalFee}
        </h3>
      </div>
      <div className="flex flex-col gap-y-[10px]">
        {oneTimeCharges?.map((category: any) => (
          <div
            key={category.id}
            className="flex flex-col gap-y-[10px] pl-28 text-12 font-bold leading-18"
          >
            <div className="flex w-full justify-between">
              <h3 className="text-12 font-bold leading-18 text-primary-500-base">
                {category.category}
              </h3>
              <h3 className="text-12 font-bold leading-18 text-primary-500-base">
                EUR{" "}
                {formatToTwoDecimalPoints(
                  category.details.reduce(
                    (sum: any, charge: any) => sum + charge.values,
                    0,
                  ),
                )}
              </h3>
            </div>
            {category.details.map((charge: any) => (
              <div
                key={charge.id}
                className="flex w-full items-center justify-between"
              >
                {isEditMode ? (
                  <div className="w-full">
                    <TextInput
                      classes={{
                        containerClassName: "w-[298px]",
                        inputClassName: "normal-input",
                      }}
                      type="text"
                      value={charge?.charge_description || charge?.name}
                      required
                      name={`charge-name-${charge.id}`}
                      onChange={(e: any) =>
                        handleChargeNameChange(
                          category.id,
                          charge.id,
                          e.target.value,
                        )
                      }
                    />
                  </div>
                ) : (
                  <h3 className="text-12 font-normal leading-18 text-primary-500-base">
                    {charge.charge_description || charge?.name}
                  </h3>
                )}
                {isEditMode ? (
                  <div className="mr-[90px] flex justify-end">
                    <TextInput
                      type="number"
                      value={charge.values}
                      required
                      placeholder="0.00"
                      name={`charge-value-${charge.id}`}
                      classes={{
                        inputClassName: "normal-input",
                        containerClassName: "w-[120px]",
                      }}
                      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                        const key = e.key;

                        // Prevent '+' and '-' signs
                        if (key === "+" || key === "-") {
                          e.preventDefault(); // Blocks the input from being processed
                        }
                      }}
                      onChange={(e: any) => {
                        // Remove non-numeric characters except the decimal point
                        e.preventDefault();
                        let inputValue = e.target.value.replace(/[^0-9.]/g, "");

                        // Ensure only one decimal point
                        const parts = inputValue.split(".");
                        if (parts.length > 2) {
                          inputValue = `${parts[0]}.${parts[1]}`;
                        }

                        // Format to two decimal places
                        if (inputValue) {
                          const numericValue = parseFloat(inputValue);
                          e.target.value = numericValue.toFixed(2); // Ensure two decimals
                        }
                        handleValueChange(
                          category.id,
                          charge.id,
                          Number(e.target.value),
                        );
                      }}
                    />
                  </div>
                ) : (
                  <h3 className="text-12 font-normal leading-18 text-primary-500-base">
                    EUR {formatToTwoDecimalPoints(charge.values)}
                  </h3>
                )}

                {isEditMode && (
                  <div className="ml-8 flex items-center justify-end">
                    <h3 className="mr-10 text-12 font-bold leading-18 text-primary-500-base">
                      EUR
                    </h3>
                    <button
                      className="flex cursor-pointer items-center disabled:cursor-not-allowed"
                      onClick={() => handleDeleteCharge(category.id, charge.id)}
                    >
                      <Icon.McsIcDelete />
                    </button>
                  </div>
                )}
              </div>
            ))}
            {isEditMode && (
              <div className="text-12 font-semibold leading-18 text-blue-1">
                <button onClick={() => handleAddCharge(category.id)}>
                  + Add another pricing line
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default OneTimeFeeComponent;
