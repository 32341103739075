import React, { useEffect, useState, useRef, useCallback } from "react";
import debounce from "lodash/debounce";
import CustomSelect from "src/components/atoms/custom-select";
import { CONSTANTS } from "src/constants";
import { useQuotationsContextFcl } from "src/context/quotations-context-fcl";
import { useCheckLocalChargesMutation } from "src/services/api-service/locations";

const TermsInputFcl = () => {
  const [checkLocalCharges] = useCheckLocalChargesMutation();
  const { formikQuotationFcl } = useQuotationsContextFcl();

  const [options, setOptions] = useState(CONSTANTS.FCL.TERMS_OPTIONS);

  const [localCharges, setLocalCharges] = useState({
    origin: false,
    destination: false,
  });

  useEffect(() => {
    // Update the show property based on localCharges
    let updatedOptions = [];
    if (formikQuotationFcl?.values?.quotation_type != "custom") {
      updatedOptions = CONSTANTS.FCL.TERMS_OPTIONS.map((option: any) => {
        if (option.value === "1") {
          // Door to port: requires origin
          return { ...option, show: localCharges.origin };
        } else if (option.value === "2") {
          // Port to port: does not require origin or destination
          return { ...option, show: true };
        } else if (option.value === "3") {
          // Door to door: requires both origin and destination
          return {
            ...option,
            show: localCharges.origin && localCharges.destination,
          };
        } else if (option.value === "4") {
          // Port to door: requires destination
          return { ...option, show: localCharges.destination };
        } else {
          // Default to hide if no matching conditions
          return { ...option, show: false };
        }
      });
      formikQuotationFcl.setFieldValue("terms", "2");
    } else {
      updatedOptions = CONSTANTS.FCL.TERMS_OPTIONS.map((option: any) => {
        return { ...option, show: true };
      });
    }

    setOptions(updatedOptions);
  }, [localCharges, formikQuotationFcl?.values?.quotation_type]);

  const prevOriginPort = useRef(formikQuotationFcl.values.origin_port);
  const prevDestinationPort = useRef(
    formikQuotationFcl.values.destination_port,
  );

  const fetchLocalCharges = useCallback(
    (port: any, type: any) => {
      if (!port) {
        return setLocalCharges((prev: any) => ({
          ...prev,
          [type]: false, // Set false in case of an error
        }));
      }
      checkLocalCharges({ locode: port, load_type: "fcl" })
        .unwrap()
        .then((res: any) => {
          setLocalCharges((prev: any) => ({
            ...prev,
            [type]: res.length > 0, // Set true if res.length > 0, otherwise false
          }));
        })
        .catch(() => {
          setLocalCharges((prev: any) => ({
            ...prev,
            [type]: false, // Set false in case of an error
          }));
        });
    },
    [checkLocalCharges],
  );

  useEffect(() => {
    const { origin_port, destination_port } = formikQuotationFcl.values;

    if (formikQuotationFcl?.values?.quotation_type != "custom") {
      fetchLocalCharges(origin_port, "origin");
      fetchLocalCharges(destination_port, "destination");
    }
  }, [
    formikQuotationFcl.values.origin_port,
    formikQuotationFcl.values.destination_port,
    fetchLocalCharges,
    formikQuotationFcl?.values?.quotation_type,
  ]);

  return (
    <CustomSelect
      name="terms"
      options={options?.filter((option: any) => option.show)}
      value={formikQuotationFcl?.values?.terms}
      onChange={(e: any) => {
        formikQuotationFcl.setFieldValue("terms", e);
      }}
      placeholder="Select"
      required={true}
      label="Terms"
    />
  );
};

export default TermsInputFcl;
