import React from "react";
import { Icon } from "src/components/atoms/icons";
import CarrierStatus from "../carrier-status";
import CarrierStatusApi from "../carrier-status-api";
import { formatDateQuotation, formatQuotationDate } from "src/helpers";

export const columns = ({ handleOpenDeleteModal }: any) => {
  return [
    {
      headerName: "Carrier Name",
      field: "name",
      width: 100,
      headerClass: "", // Add this line
      flex: 1,
      // rowDrag: true,
      suppressMovable: true,
      editable: false,
      suppressNavigable: true,
      minWidth: 200,
      resizable: true,
    },
    {
      headerName: "Code",
      field: "code",
      flex: 1,
      suppressMovable: true,
      editable: false,
      suppressNavigable: true,
      minWidth: 200,
      resizable: true,
    },
    {
      headerName: "Last Updated",
      field: "last_updated",
      flex: 1,
      suppressMovable: true,
      editable: false,
      suppressNavigable: true,
      minWidth: 200,
      resizable: false,
      cellRenderer: ({ value }: any) => {
        const convertToTitleCase = (inputString: string) => {
          return inputString
            ?.toLowerCase()
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
        };
        return <span>{formatDateQuotation(value)}</span>;
      },
    },
    {
      headerName: "API Status",
      field: "api_status",
      flex: 1,
      suppressMovable: true,
      editable: false,
      suppressNavigable: true,
      minWidth: 200,
      resizable: true,
      cellRenderer: ({ value }: any) => {
        return <CarrierStatusApi value={value} />;
      },
    },
    {
      headerName: "Status",
      field: "active_status",
      suppressMovable: true,
      editable: false,
      suppressNavigable: true,
      cellStyle: { paddingLeft: 0, paddingRight: 0 },
      minWidth: 120,
      resizable: true,
      cellRenderer: ({ data, value }: any) => {
        return <CarrierStatus value={value} id={data?.id} />;
      },
    },
    {
      headerName: "Actions",
      field: "",
      lockPosition: "right",
      suppressNavigable: true,
      width: 100,
      resizable: false,
      cellRenderer: ({ data }: any) => {
        return (
          <div className="flex h-full items-center justify-center">
            <button
              className="cursor-pointer p-8"
              onClick={() => handleOpenDeleteModal(data?.id)}
            >
              <Icon.McsIcDelete />
            </button>
          </div>
        );
      },
      cellStyle: { paddingLeft: 12, paddingRight: 12 },
      sortable: false,
    },
  ];
};
