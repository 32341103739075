import { IErrorResponse, IResponse } from "../../types";
import {
  onQueryStarted,
  transFormResponse,
  transformErrorResponse,
} from "../../api.config";
import store from "src/store";
import { rootApi } from "src/services/api";
import { ENDPOINTS } from "src/store/endpoints";

/* ------------------------------------------------------------------------------------------------------------------------------ */
/* Carriers APIs */
/* ------------------------------------------------------------------------------------------------------------------------------ */

const APIS = rootApi.injectEndpoints({
  endpoints: (build) => ({
    getCarriersList: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        const filteredParams = Object.fromEntries(
          Object.entries(body).filter(([key, value]) => value !== ""),
        );
        const queryString = new URLSearchParams(filteredParams).toString();

        return {
          url: ENDPOINTS.ADMIN.GET_CARRIERS + `?${queryString}`,
          method: "GET",
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse, dispatch) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse, dispatch) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    deleteCarrier: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        return {
          url: ENDPOINTS.ADMIN.DELETE_CARRIER + `/${body?.id}`,
          method: "DELETE",
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse, dispatch) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse, dispatch) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    syncCarriers: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        return {
          url: ENDPOINTS.ADMIN.SYNC_CARRIERS,
          method: "POST",
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse, dispatch) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse, dispatch) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    updateCarrierStatus: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        return {
          url: `${ENDPOINTS.ADMIN.UPDATE_CARRIER_STATUS}/${body?.id}`,
          method: "PUT",
          body: {
            active_status: body?.status,
          },
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse, dispatch) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: IErrorResponse, dispatch) =>
        transformErrorResponse(res, store.dispatch, true),
    }),
  }),
});
export const {
  useGetCarriersListMutation,
  useDeleteCarrierMutation,
  useSyncCarriersMutation,
  useUpdateCarrierStatusMutation,
} = APIS;
