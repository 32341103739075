import React from "react";

interface StatusEditorProps {
  value: any;
}

const CarrierStatusApi = ({ value }: StatusEditorProps) => {
  if (value) {
    return (
      <div className="flex h-full items-center justify-start">
        <div className="rounded-2xl bg-success-50 px-8 py-2 text-12 font-medium leading-18 text-success-700">
          Enabled
        </div>
      </div>
    );
  } else {
    return (
      <div className="flex h-full items-center justify-start">
        <div className="rounded-2xl bg-error-danger-50 px-8 py-2 text-12 font-medium leading-18 text-error-danger-700">
          Disabled
        </div>
      </div>
    );
  }
};

export default CarrierStatusApi;
