import React from "react";

import { QuotationRoutes } from "./types";
import {
  formatLocationForRoutes,
  formatQuotationDate,
  truncateString,
} from "src/helpers";
import QuotationHorizontalStepsFcl from "../quotation-horizonatal-steps-fcl";

const QuotationRoutesFclComponent = ({
  startDate,
  endDate,
  transitTime,
  transhipMentCount,
  data,
}: QuotationRoutes) => {
  const { source, destination } = formatLocationForRoutes(data);
  return (
    <div className="grid h-full min-h-[93px] grid-cols-12 rounded-[10px] bg-white  px-12 py-4">
      <div className="col-span-4 flex flex-col justify-around">
        <p className="text-left text-16 font-semibold leading-24 text-gray-700">
          {truncateString(`${source}`, 38)}
        </p>
        <p className="text-12 font-normal leading-18 text-gray-600">
          {formatQuotationDate(startDate)}
        </p>
      </div>
      <QuotationHorizontalStepsFcl
        value={transitTime}
        transhipMentCount={transhipMentCount}
        data={data}
        terms={data?.terms?.toString()}
      />
      <div className="col-span-4 flex flex-col items-center justify-around">
        <p className="text-right text-16 font-semibold leading-24 text-gray-700">
          {truncateString(`${destination}`, 38)}
        </p>

        <p className=" w-full text-right text-12 font-normal leading-18 text-gray-600">
          {formatQuotationDate(endDate)}
        </p>
      </div>
    </div>
  );
};

export default QuotationRoutesFclComponent;
