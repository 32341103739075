import React from "react";
import { Icon } from "../icons";
import { useNavigate } from "react-router-dom";
import { ICustomLink } from "./custom-link.types";

const CustomLink = ({ children, href, wrapperClassName = "" }: ICustomLink) => {
  const navigate = useNavigate();

  return (
    <div className={`px-24 ${wrapperClassName}`}>
      <button
        className="flex items-center gap-0.5 text-16 font-normal leading-24 text-gray-1"
        onClick={() => {
          if (href) {
            navigate(href);
          } else {
            navigate(-1);
          }
        }}
      >
        <Icon.McsIcBack />
        {children}
      </button>
    </div>
  );
};

export default CustomLink;
