import React, { useState } from "react";
import Menu from "../menu";
import { Tooltip } from "antd";
import StatusSelect from "src/components/atoms/status-select";
import useFormattedDate from "src/hooks/useFormattedDate";
import IcCopyClipboard from "src/components/atoms/icons/ic-copy-clipboard";
import { checkDefaultCustomer, formatQuotationReference } from "src/helpers";

export const columns = (handleOpenDeleteModal: any) => {
  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  return [
    {
      headerName: "Reference No.",
      field: "reference_no",
      width: 70,
      headerClass: "", // Add this line
      flex: 1,
      suppressMovable: true,
      editable: false,
      suppressNavigable: true,
      minWidth: 140,
      cellRenderer: ({ value, data }: any) => {
        console.log(value, "rows>>>");
        return (
          <p className="cursor-pointer">{formatQuotationReference(data)}</p>
        );
      },
    },
    {
      headerName: "Creation Date",
      field: "created_at",
      flex: 1,
      width: 70,
      suppressMovable: true,
      editable: false,
      suppressNavigable: true,
      minWidth: 150,
      cellRenderer: ({ value }: any) => (
        <span>
          {useFormattedDate(value).split(",")[0] +
            ", " +
            useFormattedDate(value).split(",")[1]}
        </span>
      ),
    },
    {
      headerName: "Origin",
      field: "source_country",
      flex: 1,
      suppressMovable: true,
      editable: false,
      suppressNavigable: true,
      minWidth: 120,
    },
    {
      headerName: "Destination",
      field: "destination_country",
      flex: 1,
      suppressMovable: true,
      editable: false,
      suppressNavigable: true,
      minWidth: 120,
    },
    {
      headerName: "MOT",
      field: "mode_of_transport",
      flex: 1,
      suppressMovable: true,
      editable: false,
      suppressNavigable: true,
      minWidth: 120,
      valueGetter: (params: any) => params.data.mode_of_transport.toUpperCase(),
    },
    {
      headerName: "Type",
      field: "quotation_type",
      flex: 1,
      suppressMovable: true,
      editable: false,
      suppressNavigable: true,
      minWidth: 120,
      valueGetter: (params: any) => params.data.quotation_type.toUpperCase(),
    },
    {
      headerName: "Service Type",
      field: "service_type",
      flex: 1,
      suppressMovable: true,
      editable: false,
      suppressNavigable: true,
      minWidth: 120,
      valueGetter: (params: any) => params.data.service_type.toUpperCase(),
    },
    {
      headerName: "Status",
      field: "status",
      // flex: 1,
      suppressMovable: true,
      editable: false,
      suppressNavigable: true,
      cellStyle: { paddingLeft: 0, paddingRight: 0 },
      minWidth: 120,
      cellRenderer: (params: any) => {
        return <StatusSelect value={params.data.status} id={params.data.id} />;
      },
    },
    {
      headerName: "Client",
      field: "customerName",
      flex: 1,
      suppressMovable: true,
      editable: false,
      suppressNavigable: true,
      minWidth: 150,
      resizable: true,
      cellRenderer: (val: any) => {
        const clientName = val?.data?.customer
          ? val?.data?.customer?.name
          : "-";

        const [isCopied, setIsCopied] = useState(false);

        return (
          <div className="w-full">
            {checkDefaultCustomer(val?.data?.customer) ? (
              <>
                <div className="mb-4 mr-10 block h-3">{clientName}</div>
                <div
                  className={
                    val?.data?.customer?.email
                      ? "relative flex items-center"
                      : "hidden"
                  }
                >
                  <Tooltip
                    placement="bottomLeft"
                    style={{
                      top: 1000,
                    }}
                    title={" Copied to clipboard"}
                    open={isCopied}
                    color="#E8F1F6"
                    overlayClassName="!text-red-400"
                    overlayInnerStyle={{
                      color: "#004647",
                    }}
                  ></Tooltip>

                  <button
                    className="block cursor-pointer"
                    onClick={() => {
                      setIsCopied(true);
                      copyToClipboard(val?.data?.customer?.email);
                      setTimeout(() => {
                        setIsCopied(false);
                      }, 1000);
                    }}
                  >
                    <IcCopyClipboard />
                  </button>
                  <button
                    className="overflow-hidden text-primary-300 hover:overflow-visible"
                    onClick={() => {
                      setIsCopied(true);
                      copyToClipboard(val?.data?.customer?.email);
                      setTimeout(() => {
                        setIsCopied(false);
                      }, 1000);
                    }}
                  >
                    {val?.data?.customer?.email}
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="mb-4 mr-10 block h-3">{clientName}</div>
                <div
                  className={
                    val?.data?.customer?.email
                      ? "relative flex items-center"
                      : "hidden"
                  }
                >
                  <Tooltip
                    placement="bottomLeft"
                    style={{
                      top: 1000,
                    }}
                    title={" Copied to clipboard"}
                    open={isCopied}
                    color="#E8F1F6"
                    overlayClassName="!text-red-400"
                    overlayInnerStyle={{
                      color: "#004647",
                    }}
                  ></Tooltip>

                  <button
                    className="block cursor-pointer"
                    onClick={() => {
                      setIsCopied(true);
                      copyToClipboard(val?.data?.customer?.email);
                      setTimeout(() => {
                        setIsCopied(false);
                      }, 1000);
                    }}
                  >
                    <IcCopyClipboard />
                  </button>
                  <button
                    className="overflow-hidden text-primary-300 hover:overflow-visible"
                    onClick={() => {
                      setIsCopied(true);
                      copyToClipboard(val?.data?.customer?.email);
                      setTimeout(() => {
                        setIsCopied(false);
                      }, 1000);
                    }}
                  >
                    {val?.data?.customer?.email}
                  </button>
                </div>
              </>
            )}
          </div>
        );
      },
    },
    {
      headerName: "Actions",
      field: "",
      lockPosition: "right",
      suppressNavigable: true,
      resizable: false,
      width: 100,
      cellRenderer: (params: any) => (
        <Menu params={params} handleOpenDeleteModal={handleOpenDeleteModal} />
      ),
      cellStyle: { paddingLeft: 12, paddingRight: 12 },
      sortable: false,
    },
  ];
};
