"use client";

import React, { createContext, useContext, useMemo, useState } from "react";
import { useFormik } from "formik";
import { TOASTR_TYPES } from "src/components/atoms/toast-container/types";
import { showToast } from "src/utils";
import {
  useGenerateQuotationMutation,
  useGetQuotationDetailsMutation,
} from "src/services/api-service/quotations";
import { setLoading } from "src/store/features/alerts";
import { useAppDispatch, useAppSelector } from "src/store/hook";
import { ENDPOINTS } from "src/store/endpoints";
import { useLocation, useNavigate } from "react-router-dom";
import {
  convertDataForPricings,
  convertKgCM,
  convertPoundKg,
  convertVolumeUnit,
  getCurrentDate,
  isAdmin,
} from "src/helpers";

import { quotationsSelector } from "src/store/features/quotations/selectors";
import useUser from "src/hooks/useUser";
import { SERVICE_TYPE } from "src/types";

const QuotationsContext = createContext<any>({
  formikQuotation: null,
  addCargoItem: () => null,
  addCargoItems: (items: any) => null,
  removeCargoItem: (id: string | number) => null,
  internalQuotation: (values: any, setSubmitting: any) => null,
  resetAllData: () => null,
  changeUnit: (value: string) => null,
});

export const QuotationsContextProvider = ({ children }: any) => {
  const [generateQuotationMutation] = useGenerateQuotationMutation<any>();
  const [getQuotationDetail] = useGetQuotationDetailsMutation<any>();

  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const user = useUser();
  const baseUrl = process.env.REACT_APP_API_URL;
  const downloadEndpoint = ENDPOINTS.ADMIN.GENERATE_QUOTATION;
  const navigate = useNavigate();
  const [nextLink, setNextLink] = useState("");
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id") ?? "";
  const storeData = useAppSelector(quotationsSelector);

  const locaton = useLocation();

  const [initialValues] = useState({
    ...storeData.quotationValues,
    quotation_type: isAdmin(pathname) ? "internal" : "external",
    date: getCurrentDate(),
  });

  const formikQuotation = useFormik({
    initialValues,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,

    onSubmit: handleSubmit,
  });

  const convertStringsToNumbers = (arr: any[]) => {
    arr?.length &&
      arr?.forEach((obj) => {
        Object.keys(obj).forEach((key) => {
          if (key !== "colli_type") obj[key] = Number(obj[key]);
        });
      });
  };

  const customQuotation = async (values: any) => {
    try {
      dispatch(setLoading(true));
      const token = user?.access_token;
      const downloadUrl = baseUrl + downloadEndpoint;
      const response = await fetch(downloadUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: values,
      });

      if (response.ok) {
        const contentType = response.headers.get("content-type") ?? "";
        const contentDisposition =
          response.headers.get("Content-Disposition") ?? "";
        const filenameMatch = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(
          contentDisposition,
        );
        const filename =
          filenameMatch && filenameMatch[1]
            ? filenameMatch[1].replace(/['"]/g, "")
            : "quotation.pdf";
        const message =
          response.headers.get("message") ??
          "The Pdf has been successfully exported";

        const blob = new Blob([await response.blob()], {
          type: contentType,
        });

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        showToast(TOASTR_TYPES.SUCCESS, message);
      } else {
        const errorMessage = await response.json();
        showToast(TOASTR_TYPES.ERROR, `${errorMessage?.error}`);
      }
    } catch (error: any) {
      showToast(TOASTR_TYPES.ERROR, `${error.message}`);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const convertData = (tzFormat: string) => {
    const [datePart] = tzFormat.split("T");
    const [year, month, day] = datePart.split(":");
    return `${datePart}`;
  };

  function handleSubmit(_: any, { setSubmitting }: any) {
    const values: any = JSON.parse(JSON.stringify(formikQuotation.values));
    if (values?.quotation_type === "custom") {
      setSubmitting(false);
    }

    values.charges = convertDataForPricings(values?.charges);
    // return;
    convertStringsToNumbers(values.charges["pre-carriage"]);
    convertStringsToNumbers(values.charges["origin"]);
    convertStringsToNumbers(values.charges["ocean-freight"]);
    convertStringsToNumbers(values.charges["destination"]);
    convertStringsToNumbers(values.cargo_item_specs);
    values.date = convertData(values.date);
    const filteredData = Object.fromEntries(
      Object.entries(values).filter(([key, value]) => value !== ""),
    );
    const formData = new FormData();
    Object.entries(filteredData).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        if (key === "charges" || key === "cargo_item_specs") {
          formData.append(key, JSON.stringify(value));
        } else {
          formData.append(key, value.toString());
        }
      }
    });
    if (values?.quotation_type === "custom") {
      customQuotation(formData);
    }
    if (
      values?.quotation_type === "internal" ||
      values?.quotation_type === "external"
    ) {
      internalQuotation(values, setSubmitting);
    }
  }

  const internalQuotation = (values: any, setSubmitting: any) => {
    const data: any = {
      user_id: user.id,
      source_location: values.source_location,
      source_country: values.source_country,
      source_port: values.source_port,
      destination_location: values.destination_location,
      destination_country: values.destination_country,
      destination_port: values.destination_port,
      date: values.date,
      mode_of_transport: values.mode_of_transport,
      quotation_type: values.quotation_type,
      service_type: values.service_type,
      customer_id: values.customer_id,
      reference_no: values.reference_no,
      cargo_specs: {
        is_cm: values?.is_cm,
        is_cargo_total_dimension: values?.total_cargo_dimensions,
        ...(values?.total_cargo_dimensions
          ? {
              total_dimensions: {
                volume: values?.cargo_item_specs[0]?.volume || 0,
                weight: values?.cargo_item_specs[0]?.weight || 0,
              },
              // specs: [],
            }
          : {
              specs: values?.cargo_item_specs,
            }),
      },
    };

    if (!locaton.pathname.includes("/admin/quotations/results")) {
      data["main_quotation_id"] = id;
    }
    generateQuotationMutation(data)
      .unwrap()
      .then((response: any) => {
        showToast(TOASTR_TYPES.SUCCESS, response?.message);
        if (locaton.pathname.includes("/quotations/results")) {
          getQuotationDetail({ id: response?.data?.id });
        }
        if (!location.pathname.includes("admin")) {
          navigate(
            `/quotations/results?id=${response?.data?.id}&serviceType=${SERVICE_TYPE.LCL}`,
          );
        } else {
          navigate(
            `/admin/quotations/results?id=${response?.data?.id}&serviceType=${SERVICE_TYPE.LCL}`,
          );
        }
      })
      .catch((err: any) => {
        setTimeout(() => {
          setNextLink("same");
        }, 500);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const addCargoItem = () => {
    const specs: any[] = JSON.parse(
      JSON.stringify(formikQuotation.values.cargo_item_specs),
    );
    specs.push({
      cm_kg: 1,
      colli_type: "Pallet",
      quantity: "",
      length: "",
      width: "",
      height: "",
      volume: "",
      weight: "",
      stackable: 1,
    });
    formikQuotation?.setValues({
      ...formikQuotation.values,
      cargo_item_specs: [...specs],
    });
  };

  const addCargoItems = (values: any) => {
    formikQuotation?.setValues({
      ...formikQuotation.values,
      cargo_item_specs: [...values],
    });
  };

  const removeCargoItem = (id: string | number) => {
    const updatedItems = formikQuotation?.values.cargo_item_specs.filter(
      (bio: any, i: number) => i !== id,
    );

    formikQuotation?.setValues({
      ...formikQuotation.values,
      cargo_item_specs: updatedItems,
    });
  };

  const changeUnit = (unit: string) => {
    const updatedItems = formikQuotation?.values?.cargo_item_specs?.map(
      (item: any) => {
        const height = convertKgCM(item.height, unit);
        const width = convertKgCM(item.width, unit);
        const length = convertKgCM(item.length, unit);
        const weight = convertPoundKg(item.weight, unit);
        const volume = convertVolumeUnit(item.volume, unit);

        return {
          ...item,
          cm_kg: unit ? 1 : 0,
          length: length,
          width: width,
          height: height,
          weight: weight,
          volume: volume,
        };
      },
    );

    const updatedArray = {
      ...formikQuotation.values,
      cargo_item_specs: updatedItems,
    };

    formikQuotation?.setValues(updatedArray);
  };

  const resetAllData = () => {
    formikQuotation.setValues({
      ...initialValues,
      cargo_item_specs: [
        {
          cm_kg: 1,
          colli_type: "Pallet",
          quantity: "",
          length: "",
          width: "",
          height: "",
          volume: "",
          weight: "",
          stackable: 1,
        },
      ],
    });
  };

  const value = useMemo(
    () => ({
      formikQuotation,
      initialValues,
      addCargoItem,
      removeCargoItem,
      internalQuotation,
      nextLink,
      setNextLink,
      resetAllData,
      addCargoItems,
      changeUnit,
    }),
    [
      formikQuotation,
      initialValues,
      addCargoItem,
      removeCargoItem,
      internalQuotation,
      nextLink,
      setNextLink,
      resetAllData,
      addCargoItems,
      changeUnit,
    ],
  );
  return (
    <QuotationsContext.Provider value={value}>
      {children}
    </QuotationsContext.Provider>
  );
};

export const useQuotationsContext = () => useContext(QuotationsContext);
