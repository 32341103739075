import { useState, useEffect, useCallback, useRef } from "react";
import { useGetLocationsMutation } from "src/services/api-service/locations";
import { ENDPOINTS } from "src/store/endpoints";

const useGetLazyPorts = () => {
  const [ports, setPorts] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const debounceTimer = useRef<NodeJS.Timeout | null>(null);
  const abortControllerRef = useRef<AbortController | null>(null);

  const [pagination, setPagination] = useState({
    page: 1,
    hasMore: true,
    isLoading: false,
  });

  const [getLocations] = useGetLocationsMutation();

  const fetchData = useCallback(
    async (currentPage: number, query: string) => {
      if (pagination.isLoading || !pagination.hasMore) return;

      // Cancel previous request
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      // Create a new AbortController for the new request
      const newAbortController = new AbortController();
      abortControllerRef.current = newAbortController;

      setPagination((prev) => ({ ...prev, isLoading: true }));

      try {
        const res: any = await getLocations({
          endpoint: ENDPOINTS.ADMIN.GET_ALL_PORTS,
          current_page: currentPage,
          search: query,
          per_page: 30,
          signal: newAbortController.signal, // Attach the signal to cancel requests
        }).unwrap();

        console.log(res, "res......");

        const newPorts = res?.data || [];

        setPorts((prev) =>
          currentPage === 1 ? newPorts : [...prev, ...newPorts],
        );

        setPagination((prev) => ({
          ...prev,
          hasMore: res?.next_page_url,
          isLoading: false,
        }));
      } catch (err: any) {
        if (err.name === "AbortError") {
          console.log("Request was aborted.");
        } else {
          console.error("Error fetching ports:", err);
          setPagination((prev) => ({
            ...prev,
            hasMore: false,
            isLoading: false,
          }));
        }
      }
    },
    [pagination.page, searchValue],
  );

  useEffect(() => {
    if (debounceTimer.current) clearTimeout(debounceTimer.current);

    debounceTimer.current = setTimeout(() => {
      fetchData(pagination.page, searchValue);
    }, 300);

    return () => {
      if (debounceTimer.current) clearTimeout(debounceTimer.current);
    };
  }, [pagination.page, searchValue]);

  const loadNextPage = () => {
    if (!pagination.isLoading && pagination.hasMore) {
      setPagination((prev) => ({ ...prev, page: prev.page + 1 }));
    }
  };

  const handleSearch = (query: string) => {
    setSearchValue(query);
    setPorts([]);
    setPagination((prev) => ({ ...prev, page: 1, hasMore: true }));
  };

  return {
    ports,
    loadNextPage,
    handleSearch,
    pagination,
    searchValue,
    setSearchValue,
  };
};

export default useGetLazyPorts;
