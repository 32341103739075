import React, { useEffect, useLayoutEffect, useState } from "react";

import { columns } from "./columns";
import usePagination from "src/hooks/usePagination";
import { useAppDispatch } from "src/store/hook";
import CustomDatatable from "src/components/organisms/datatable/elements";
import CustomButton from "src/components/atoms/button";
import { Icon } from "src/components/atoms/icons";
import CustomModal from "src/components/molecules/custom-modal";
import TextInput from "src/components/atoms/text-input";
import { Input } from "antd";
import ConfirmDeleteComponent from "src/components/organisms/confirm-delete/confirm-delete.component";
import CustomSelect from "src/components/atoms/custom-select";
import {
  useDeleteMarginGroupMutation,
  useGetCustomerNamesMutation,
  useGetMarginGroupByIdMutation,
  useGetMarginGroupsMutation,
} from "src/services/api-service/margin-groups";
import { showToast } from "src/utils";
import { TOASTR_TYPES } from "src/components/atoms/toast-container/types";
import { setLoading } from "src/store/features/alerts";
import { ENDPOINTS } from "src/store/endpoints";
import { useNavigate } from "react-router-dom";
import useLoadingError from "src/hooks/useLoadingError";
import AddCustomerContainer from "src/pages/user-management/elements/customers/datatable/add-customer/add-customer.container";
import useCheckDisabled from "src/hooks/useCheckDisabled";
import useUser from "src/hooks/useUser";

const Datatable = ({ formik, resetError, getId, reFetchData }: any) => {
  const [getListMutation] = useGetMarginGroupsMutation<any>();
  const [deleteItemMutation] = useDeleteMarginGroupMutation<any>();
  const [getItemMutation] = useGetMarginGroupByIdMutation<any>();
  const [getCustomersMutation] = useGetCustomerNamesMutation<any>();

  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [isAddCustomerModalOpen, setAddCustomerModalOpen] = useState(false);
  const dispatch = useAppDispatch();
  const [isDownload, setIsDownload] = useState(false);
  const [updateId, setUpdateId] = useState("");
  const [options, setOptions] = useState<any[]>([]);
  const baseUrl = process.env.REACT_APP_API_URL;
  const downloadEndpoint = ENDPOINTS.ADMIN.DOWNLOAD_MARGIN_GROUP;
  const [searchValue, setSearchValue] = useState("");
  const { startLoading, stopLoading } = useLoadingError();
  const navigate = useNavigate();
  const user = useUser();

  const {
    pagination,
    setPagination,
    handlePageChange,
    handleItemsPerPageChange,
    data,
    handleSearch,
    clearSearch,
    fetchData,
  } = usePagination({
    key: "margin-groups-datatable",
    mutation: getListMutation,
  });

  const [storedData, setStoredData] = useState<any>({
    name: "",
    customerType: "",
    description: "",
    customerId: [],
  });

  const requiredKeys = ["name", "customerId"];
  const { isDisabled, handleInputChange } = useCheckDisabled();
  useLayoutEffect(() => {
    const customerId: string[] = formik.values.customerId;
    const formValues = { ...formik.values, customerId: customerId.join(",") };
    const delayDebounceFn = setTimeout(() => {
      handleInputChange(formValues, requiredKeys, storedData, updateId);
    }, 200);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [formik.values, isDisabled, storedData, updateId]);

  const handleCloseAddCustomerModal = (data: any) => {
    setAddCustomerModalOpen(false);
    if (data) {
      setOptions([data].concat(options));
    }
  };

  useLayoutEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getCustomerNames();
    }, 200);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [searchValue]);

  useEffect(() => {
    getCustomerNames();
  }, []);

  useEffect(() => {
    if (!isDeleteModalOpen) {
      updateId && getDataById();
    }
  }, [updateId]);

  useEffect(() => {
    if (!isDeleteModalOpen) {
      !isAddModalOpen && setUpdateId("");
    }
  }, [isAddModalOpen]);

  useEffect(() => {
    if (isDownload) {
      downloadDataById();
    }
  }, [isDownload]);

  const strToNmbrArr = (arr: string[]) => {
    return arr
      .filter((item) => /^\d+$/.test(item))
      .map((numStr) => parseInt(numStr));
  };

  const getDataById = async () => {
    if (updateId) {
      const data: any = await getItemMutation({ id: updateId })
        .unwrap()
        .catch((err: any) => {
          // console.log(err)
        });
      const finalData: any = data?.data;
      const customers: any = finalData?.customers;
      const fieldsToUpdate: any[] = Object.keys(formik?.values) || [];

      fieldsToUpdate.forEach((field) => {
        if (
          finalData?.[field] &&
          field !== "customerId" &&
          field !== "margin_percentage"
        ) {
          formik.setFieldValue(field, finalData?.[field]);
        }
        if (finalData?.[field] && field === "margin_percentage") {
          formik.setFieldValue(field, parseFloat(finalData?.[field]));
        }
        if (field === "customerId") {
          finalData?.customers?.length > 0 &&
            formik.setFieldValue(
              field,
              strToNmbrArr(finalData?.[field].split(",")),
            );
        }
      });
      setStoredData(finalData);
      setAddModalOpen(true);
      setOptions((prev) => {
        return prev.concat(customers);
      });
      getId(updateId);
    }
  };

  const getCustomerNames = async () => {
    const data: any = await getCustomersMutation({ search: searchValue })
      .unwrap()
      .catch((err: any) => {
        // console.log(err)
      });
    setOptions(data?.data);
  };

  const handleDownload = () => {
    setIsDownload(true);
  };

  const getFilenameFromHeaders = (headers: any) => {
    const contentDisposition = headers.get("Content-Disposition");
    if (contentDisposition && contentDisposition.indexOf("attachment") !== -1) {
      const filenameMatch = contentDisposition.match(
        /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
      );
      if (filenameMatch && filenameMatch[1]) {
        return filenameMatch[1].replace(/['"]/g, "");
      }
    }
    return null;
  };

  const downloadDataById = async () => {
    try {
      dispatch(setLoading(true));
      const token = user?.access_token;
      const downloadUrl = baseUrl + downloadEndpoint;
      const response = await fetch(downloadUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.ok) {
        const filename = getFilenameFromHeaders(response.headers);
        const blob = new Blob([await response.blob()], {
          type: response.headers.get("content-type") ?? "",
        });

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = filename || "Margin_Groups.pdf"; // Specify the desired filename here
        link.click();
        showToast(
          TOASTR_TYPES.SUCCESS,
          `You successfully downloaded the datasource`,
        );
      } else {
        console.error(
          "Error downloading file. Server returned:",
          response.status,
        );
      }
    } finally {
      setIsDownload(false);
      dispatch(setLoading(false));
    }
  };

  const handleCellClick = (event: any) => {
    if (event?.colDef?.headerName !== "Actions") {
      const params = {
        id: event?.data?.id,
        name: event?.data?.name,
      };
      const queryString = new URLSearchParams(params).toString();
      navigate("/admin/margin-groups/cost-groups?" + queryString);
    }
  };

  const handleOpenAddModal = () => {
    setAddModalOpen(true);
  };

  const handleOpenEditModal = (id: any) => {
    setUpdateId(id);
  };

  const handleCloseAddModal = () => {
    setAddModalOpen(false);
    setUpdateId("");
    formik.resetForm();
  };

  const handleOpenDeleteModal = (id: any) => {
    setDeleteModalOpen(true);
    setUpdateId(id);
  };

  const [columnDefs] = useState<any[]>(
    columns({ handleOpenDeleteModal }, { handleOpenEditModal }),
  );

  const { TextArea } = Input;

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const handleConfirmDelete = async () => {
    const body = {
      id: updateId,
    };
    try {
      startLoading();
      if (updateId) {
        const response = await deleteItemMutation(body).unwrap();
        if (response) {
          showToast(TOASTR_TYPES.SUCCESS, response.message);
          fetchData({ currentPage: pagination?.currentPage, query: "" });
        }
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      stopLoading();
      setDeleteModalOpen(false);
    }
  };

  const handleSearchInputChange = (e: any) => {
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    if (reFetchData) {
      fetchData({ currentPage: pagination?.currentPage, query: "" });
      getCustomerNames();
      setAddModalOpen(false);
      setUpdateId("");
    }
  }, [reFetchData]);

  return (
    <>
      <CustomDatatable
        title={"Margin Groups"}
        description={`${pagination.total} Total Margin Group${pagination.total > 1 ? "s" : ""}`}
        className="date-picker"
        data={data}
        columns={columnDefs}
        showHeader={true}
        showFooter={true}
        pagination={pagination}
        setPagination={setPagination}
        handleSearch={handleSearch}
        clearSearch={clearSearch}
        handlePageChange={handlePageChange}
        handleItemsPerPageChange={handleItemsPerPageChange}
        rowDraggable={false}
        onCellClicked={(event: any) => handleCellClick(event)}
        button={
          <form onSubmit={formik.handleSubmit}>
            <div className="flex items-center">
              <div className="flex items-center gap-3">
                <CustomButton
                  disabled={!data?.length}
                  variant="secondary-icon-btn"
                  onClick={handleDownload}
                >
                  <div className="flex items-center gap-2">
                    <i className="shrink-0">
                      <Icon.McsIcExportPdf />
                    </i>
                    <span>Export PDF</span>
                  </div>
                </CustomButton>
                <CustomButton
                  variant="primary-icon-btn"
                  onClick={handleOpenAddModal}
                >
                  <div className="flex items-center gap-2">
                    <i className="shrink-0">
                      <Icon.McsIcIconPlus />
                    </i>
                    <span>Add Group</span>
                  </div>
                </CustomButton>
              </div>
              {/* add group modal */}
              <CustomModal
                title={(updateId ? "Edit" : "Add") + " Group"}
                isOpen={isAddModalOpen}
                toggle={handleCloseAddModal}
                maskClosable={false}
                icon={<Icon.McsIcChevronBack />}
                width={728}
                innerClass="custom-common-modal"
                footer={
                  <div className="w-full justify-end gap-4 lg:flex">
                    <div className="order-1 lg:order-2">
                      <CustomButton
                        variant="primary"
                        htmlType="submit"
                        className="w-full"
                        disabled={isDisabled}
                      >
                        Save
                      </CustomButton>
                    </div>
                    <div className="order-2 mt-10 lg:order-1 lg:mt-0">
                      <CustomButton
                        variant="secondary"
                        onClick={handleCloseAddModal}
                        className="w-full"
                      >
                        Cancel
                      </CustomButton>
                    </div>
                  </div>
                }
              >
                <div className="flex flex-col gap-4">
                  <div>
                    <TextInput
                      name="name"
                      type="text"
                      label="Group Name"
                      placeholder="Enter name here.."
                      classes={{
                        containerClassName: "custom-input",
                        inputClassName: "",
                      }}
                      value={formik.values.name}
                      disabled={formik.values.name === "Default"}
                      showError={Boolean(formik.touched.name)}
                      onChange={(e) => {
                        resetError();
                        formik.handleChange(e);
                      }}
                      onBlur={(e) => {
                        formik.handleBlur(e);
                        resetError();
                      }}
                      errorMessage={formik.errors.name}
                      required={true}
                    />
                  </div>
                  <div>
                    <TextInput
                      name="customerType"
                      type="text"
                      label="Customer Type"
                      placeholder="Enter type here.."
                      classes={{
                        containerClassName: "custom-input",
                        inputClassName: "",
                      }}
                      value={formik.values.customerType}
                      showError={Boolean(formik.touched.customerType)}
                      onChange={(e) => {
                        resetError();
                        formik.handleChange(e);
                      }}
                      onBlur={(e) => {
                        formik.handleBlur(e);
                        resetError();
                      }}
                      errorMessage={formik.errors.customerType}
                    />
                  </div>
                  <div>
                    <TextInput
                      name="margin_percentage"
                      type="text"
                      label="Margin Percentage"
                      placeholder="Enter type here.."
                      classes={{
                        containerClassName: "custom-input",
                        inputClassName: "",
                      }}
                      value={formik.values.margin_percentage}
                      showError={Boolean(formik.touched.margin_percentage)}
                      onChange={(e) => {
                        resetError();
                        formik.handleChange(e);
                      }}
                      onBlur={(e) => {
                        formik.handleBlur(e);
                        resetError();
                      }}
                      errorMessage={formik.errors.margin_percentage}
                      required={true}
                    />
                  </div>
                  <div>
                    <label className="mb-6 block text-14 font-medium leading-20 text-gray-700">
                      Description
                    </label>
                    <TextArea
                      name="description"
                      placeholder="Customers who are E-commerce retailers"
                      allowClear
                      className="custom-ant-input-affix-wrapper"
                      value={formik.values.description}
                      onChange={(e) => {
                        resetError();
                        formik.handleChange(e);
                      }}
                      onBlur={(e) => {
                        formik.handleBlur(e);
                        resetError();
                      }}
                    />
                  </div>
                  <div>
                    <CustomSelect
                      mode="tags"
                      label="Add Customer to this Group"
                      name="customerId"
                      placeholder="Select options"
                      options={options?.map((item: any) => ({
                        value: item?.id,
                        label: item.name,
                      }))}
                      value={formik?.values?.customerId}
                      showError={Boolean(formik.touched.customerId)}
                      onChange={(e: any) => {
                        resetError();
                        formik.setFieldValue("customerId", e);
                      }}
                      onBlur={(e) => {
                        formik.handleBlur(e);
                        resetError();
                      }}
                      errorMessage={formik.errors.customerId}
                      required={true}
                      dropdownRender={(menu) => (
                        <div>
                          <div className="border-b border-neutral-700 px-16 py-10">
                            <div>
                              <div className="flex justify-between gap-2">
                                <div className="w-full">
                                  {" "}
                                  <TextInput
                                    name="search"
                                    type="text"
                                    placeholder="Search customer"
                                    value={searchValue}
                                    onChange={handleSearchInputChange}
                                    prefix={<Icon.McsIcSearch />}
                                    suffix={
                                      <button
                                        onClick={() => {
                                          setSearchValue("");
                                        }}
                                      >
                                        <i hidden={searchValue ? false : true}>
                                          <Icon.McsIcMenu />
                                        </i>
                                      </button>
                                    }
                                  />
                                </div>
                                <div>
                                  <CustomButton
                                    variant="primary-icon-btn"
                                    onClick={() => {
                                      handleOpenAddModal();
                                      setAddCustomerModalOpen(true);
                                    }}
                                  >
                                    <div className="flex items-center gap-2">
                                      <i className="shrink-0">
                                        <Icon.McsIcIconPlus />
                                      </i>
                                      <span>Add New</span>
                                    </div>
                                  </CustomButton>
                                </div>
                              </div>
                            </div>
                          </div>
                          {menu}
                        </div>
                      )}
                      suffixIcon={<Icon.McsIcDownArrow />}
                    />
                  </div>
                </div>
              </CustomModal>
              {/* add group modal end */}
            </div>
          </form>
        }
      />
      {/* Delete modal */}
      <ConfirmDeleteComponent
        title="Are you sure you want to delete this Group?"
        message="Deleted data cannot be retrieved"
        isOpen={isDeleteModalOpen}
        toggle={handleCloseDeleteModal}
        onConfirm={handleConfirmDelete}
        onCancel={handleCloseDeleteModal}
        className="custom-delete-modal"
      />
      {isAddCustomerModalOpen && (
        <AddCustomerContainer
          handleCloseAddModal={handleCloseAddCustomerModal}
        />
      )}
    </>
  );
};

export default Datatable;
