import { useState, useEffect } from "react";
import { checkValidCharges, checkValidOneTimeCharges } from "src/helpers";

function useValidCharges(chargesData: any) {
  console.log(chargesData, "chargesData>>>>>");

  const [isValidCharges, setIsValidCharges] = useState(true);

  useEffect(() => {
    const validateCharges = () => {
      return (
        checkValidCharges(chargesData) || checkValidOneTimeCharges(chargesData)
      );
    };

    setIsValidCharges(validateCharges());
  }, [chargesData]);

  return isValidCharges;
}

export default useValidCharges;
