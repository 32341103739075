import React from "react";
import ContentWrapper from "src/components/layouts/content-wrapper";
import CustomerPanelLayout from "src/components/layouts/customer-panel-layout";
import useQueryParams from "src/hooks/useQueryParams";
import { SERVICE_TYPE } from "src/types";
import QuotationResultsFcl from "../../../components/molecules/fcl/quotation-results-fcl";
import QuotationsResultsLcl from "../../../components/molecules/lcl/quotation-results-lcl";

const CustomerQuotationsResultComponent = () => {
  const { getQueryParam } = useQueryParams();
  const serviceType: string = getQueryParam("serviceType");
  const id: string = getQueryParam("id");

  const href = `/quotations?tab=Generate%20Quotations&id=${id}`;

  return (
    <CustomerPanelLayout
      isSidebarOpen={true}
      isResultsPage={true}
      breadcrumbsData={[
        { title: "Generate Quotations", href: href },
        { title: "Results" },
      ]}
    >
      <ContentWrapper>
        {serviceType == SERVICE_TYPE.LCL && (
          <QuotationsResultsLcl href={href} />
        )}
        {serviceType == SERVICE_TYPE.FCL && <QuotationResultsFcl href={href} />}
      </ContentWrapper>
    </CustomerPanelLayout>
  );
};

export default CustomerQuotationsResultComponent;
