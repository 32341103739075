import React, { useEffect, useMemo, useState } from "react";
import TableData from "./table-data";
import CustomButton from "src/components/atoms/button";
import { Icon } from "src/components/atoms/icons";
import TableRow from "./table-row";

import VerticalStepsFcl from "../vertical-steps-fcl";
import {
  calculateFinalTotalPrice,
  calculateSubTotalPerContainer,
  calculateTotalPerCharge,
  generateUUID,
  sortCharges,
} from "src/helpers";
import { setSelectedQuotations } from "src/store/features/quotations";
import { useAppDispatch, useAppSelector } from "src/store/hook";
import { quotationsSelector } from "src/store/features/quotations/selectors";
import OneTimeFee from "./one-time-fee";

const ChargesTableFclComponent = ({
  showEditButton = true,
  defaultEditable = false,
  stickyColClassName = "bg-primary-50",
  chargesData,
  setChargesData,
  isValidCharges,
  quotation,
  initialCharges,
  initialOneTimeCharges,
  handleUpdateChargesFcl,
  isEditMode,
  setIsEditMode,
  oneTimeCharges,
  setOneTimeCharges,
}: any) => {
  const dispatch = useAppDispatch();

  const total = useMemo(() => {
    return calculateFinalTotalPrice({
      charges: chargesData?.charges,
      columns: chargesData?.columns,
      oneTimeCharges: oneTimeCharges,
    });
  }, [chargesData, oneTimeCharges]); // Recalculate only when data changes

  const { data } = useAppSelector(quotationsSelector);

  const handleInputChange = ({
    value,
    chargeId,
    subChargeId,
    inputIndex,
  }: {
    value: any;
    chargeId: number;
    subChargeId: number;
    inputIndex: number;
  }) => {
    value = isNaN(parseFloat(value)) ? null : Math.abs(value);

    setChargesData((prevData: any) => {
      const updatedCharges = prevData.charges.map((charge: any) => {
        if (charge.id === chargeId) {
          const updatedDetails = charge.details.map((detail: any) => {
            if (detail.id === subChargeId) {
              const updatedValues: any = [...detail.values];
              updatedValues[inputIndex] = isNaN(parseFloat(value))
                ? null
                : parseFloat(value); // Update the specific value
              return { ...detail, values: updatedValues };
            }
            return detail;
          });
          return { ...charge, details: updatedDetails };
        }
        return charge;
      });

      return { ...prevData, charges: updatedCharges };
    });
  };

  const handleTitleInputChange = ({
    value,
    chargeId,
    subChargeId,
  }: {
    value: string;
    chargeId: number;
    subChargeId: number;
  }) => {
    setChargesData((prevData: any) => {
      const updatedCharges = prevData.charges.map((charge: any) => {
        if (charge.id === chargeId) {
          const updatedDetails = charge.details.map((detail: any) => {
            if (detail.id === subChargeId) {
              return { ...detail, name: value, charge_description: value }; // Update the name of the detail
            }
            return detail;
          });
          return { ...charge, details: updatedDetails };
        }
        return charge;
      });

      return { ...prevData, charges: updatedCharges };
    });

    console.log(
      `Updated name at chargeId ${chargeId}, subChargeId ${subChargeId}:`,
      value,
    );
  };

  const handleDeleteCharge = ({
    chargeId,
    subChargeId,
  }: {
    chargeId: number;
    subChargeId: number;
  }) => {
    setChargesData((prevState: any) => {
      const updatedCharges = prevState.charges.map((charge: any) => {
        if (charge.id === chargeId) {
          // Filter out the subcharge with matching subChargeId
          const updatedDetails = charge.details.filter(
            (detail: any) => detail.id !== subChargeId,
          );
          return { ...charge, details: updatedDetails };
        }
        return charge;
      });

      return { ...prevState, charges: updatedCharges };
    });
  };

  const addPricingLine = ({ chargeId }: { chargeId: number }) => {
    setChargesData((prevState: any) => {
      const updatedCharges = prevState.charges.map((charge: any) => {
        if (charge.id === chargeId) {
          return {
            ...charge,
            details: [
              ...charge.details,
              {
                id: generateUUID(),
                name: "", // Empty name for the new line,
                charge_description: "",
                values: Array(prevState.columns.length).fill(""), // Array of empty strings
                isEditable: true, // Assume new lines are editable
              },
            ],
          };
        }
        return charge;
      });

      return { ...prevState, charges: updatedCharges };
    });
  };

  const getTotalChargesById = (chargeId: number, colIndex: number) => {
    return calculateTotalPerCharge({
      colIndex,
      chargeId,
      charges: chargesData?.charges,
    });
  };

  const [expandedRows, setExpandedRows] = useState<{ [key: number]: boolean }>(
    () => {
      const initialExpandedState: { [key: number]: boolean } = {};
      chargesData?.charges.forEach((_: any, index: any) => {
        initialExpandedState[index] = true; // Open all rows by default
      });
      return initialExpandedState;
    },
  );

  const expandCollapseRows = (expand: boolean) => {
    const expandedState: { [key: number]: boolean } = {};
    chargesData?.charges.forEach((_: any, index: any) => {
      expandedState[index] = expand; // Open all rows by default
    });
    setExpandedRows(expandedState);
  };

  useEffect(() => {
    expandCollapseRows(true);
  }, [chargesData?.charges?.length]);

  // Toggle row expansion
  const toggleRow = (index: number) => {
    setExpandedRows((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  return (
    <>
      <div className="my-12 flex justify-end">
        {showEditButton && (
          <>
            {isEditMode ? (
              <>
                <CustomButton
                  variant="primary-icon-btn"
                  onClick={handleUpdateChargesFcl}
                  disabled={!isValidCharges}
                  className="mr-8"
                >
                  <div className="flex items-center gap-0.5 ">
                    <i className="shrink-0">
                      <Icon.IcTickArrow />
                    </i>
                    <span>Save change</span>
                  </div>
                </CustomButton>
                <CustomButton
                  variant="secondary-icon-btn"
                  onClick={() => {
                    setIsEditMode(false);
                    setChargesData(initialCharges);
                    setOneTimeCharges(initialOneTimeCharges);
                  }}
                >
                  <div className="flex items-center gap-0.5">
                    <i className="text-invert shrink-0">
                      <Icon.IcCloseBtn />
                    </i>
                    <span>Cancel</span>
                  </div>
                </CustomButton>
              </>
            ) : (
              <CustomButton
                className=""
                variant="primary-icon-btn"
                onClick={() => {
                  setIsEditMode(true);
                  expandCollapseRows(true);
                  dispatch(setSelectedQuotations([]));

                  console.log("Edit Pricings");
                }}
              >
                <div className="flex items-center gap-0.5">
                  <i className="shrink-0">
                    <Icon.McsIcEditWhite />
                  </i>
                  <span>Edit Pricings</span>
                </div>
              </CustomButton>
            )}
          </>
        )}
      </div>
      <div className="flex justify-between">
        {!isEditMode && (
          <div className="gap-35 flex w-[30%] flex-col">
            <VerticalStepsFcl
              quotation={quotation}
              isTransshipment={quotation?.isTransshipment}
              terms={data?.terms}
            />
          </div>
        )}
        <div className="" style={{ width: isEditMode ? "100%" : "70%" }}>
          {/* Wrapper for horizontal scrolling */}
          <div className="custom-scroll overflow-x-auto">
            <table
              className="min-w-full"
              style={{ tableLayout: "fixed" }} // Enforces fixed column widths
            >
              <thead>
                <tr className="border-b border-border text-gray-800">
                  <th
                    className={` py-10  text-start font-semibold ${stickyColClassName}`}
                    style={{
                      position: "sticky",
                      left: 0,
                      zIndex: 10,
                      width: "400px", // Fixed width for the first column
                    }}
                  >
                    <div className="w-[400px]">Charge Details</div>
                  </th>
                  {chargesData.columns
                    .filter((column: any) => {
                      return !column?.hide;
                    })
                    .map((column: any, index: any) => (
                      <th
                        key={index}
                        className="py-10  text-center font-semibold"
                      >
                        <div className="flex justify-end">
                          <div className="flex w-[120px] justify-center">
                            {column?.container_type == "PER SHIPMENT"
                              ? "Per Shipment"
                              : column?.container_type}
                          </div>
                        </div>
                      </th>
                    ))}

                  <th
                    key={"index"}
                    className="w-[10%]  py-10 text-end font-semibold"
                  >
                    <div className="flex w-full justify-end">Currency</div>
                  </th>

                  {isEditMode && (
                    <th
                      className={`py-10  text-start font-semibold ${stickyColClassName}`}
                      style={{
                        position: "sticky",
                        right: 0,
                        zIndex: 10,
                        width: "30px",
                      }}
                    >
                      <div className="invisible w-[30px] bg-primary-50">
                        {/* Action */}
                      </div>
                    </th>
                  )}
                </tr>
              </thead>
              <tbody className="">
                {sortCharges(chargesData.charges)?.map(
                  (charge: any, chargeIndex: any) => (
                    <React.Fragment key={chargeIndex}>
                      <TableRow
                        data={chargesData}
                        chargeIndex={chargeIndex}
                        expandedRows={expandedRows}
                        charge={charge}
                        toggleRow={toggleRow}
                        isExpandable={false}
                        handleInputChange={handleInputChange}
                        getTotalChargesById={getTotalChargesById}
                        stickyColClassName={stickyColClassName}
                      />

                      {/* Expandable row */}
                      {charge.details.map((detail: any, detailIndex: any) => (
                        <TableRow
                          data={chargesData}
                          key={detailIndex}
                          detailIndex={detailIndex}
                          detail={detail}
                          showDelete={isEditMode}
                          expandedRows={expandedRows}
                          isExpandable={true}
                          charge={charge}
                          chargeIndex={chargeIndex}
                          isEditMode={isEditMode}
                          showAddMoreBtn={
                            charge?.isEditable &&
                            charge.details.length == detailIndex + 1 &&
                            isEditMode
                          }
                          addPricingLine={addPricingLine}
                          handleDeleteCharge={handleDeleteCharge}
                          handleInputChange={handleInputChange}
                          handleTitleInputChange={handleTitleInputChange}
                          stickyColClassName={stickyColClassName}
                          // isEditable={charge?.isEditable}
                        />
                      ))}
                      {/* *********** */}

                      {charge?.isEditable && isEditMode && (
                        <tr>
                          <td
                            colSpan={6}
                            style={{
                              position: "sticky",
                              left: 0,
                              zIndex: 10,
                            }}
                          >
                            <div className="w-[400px] py-10  text-12 font-semibold leading-18 text-blue-1">
                              <button
                                onClick={() => {
                                  addPricingLine({ chargeId: charge?.id });
                                  if (!expandedRows[chargeIndex]) {
                                    toggleRow(chargeIndex);
                                  }
                                }}
                              >
                                + Add another pricing line
                              </button>
                            </div>
                          </td>
                        </tr>
                      )}

                      {/* Border */}
                      <tr className="border-b border-[#AFB6D1]">
                        <div></div>
                      </tr>
                      {/* *** */}
                    </React.Fragment>
                  ),
                )}

                <tr className="cursor-pointer">
                  <td
                    className={`cursor-pointer  text-start font-medium text-primary-600 ${stickyColClassName}`}
                    style={{
                      position: "sticky",
                      left: 0,
                      zIndex: 10,
                      width: "400px", // Fixed width for the first column
                    }}
                  >
                    <div className="w-[400px] py-10 text-16 font-medium text-primary-600">
                      Subtotal per container
                    </div>
                  </td>
                  {chargesData.columns
                    .filter((column: any) => {
                      return !column?.hide;
                    })
                    .map((_: any, colIndex: any) => (
                      <TableData
                        className="text-16  font-semibold"
                        key={colIndex}
                        value={calculateSubTotalPerContainer({
                          colIndex,
                          charges: chargesData?.charges,
                          quantity: chargesData?.columns?.[colIndex]?.quantity,
                        })}
                      />
                    ))}
                  <TableData
                    className="!w-[100px] !justify-end font-bold"
                    key={"colIndex"}
                    value={"EUR"}
                  />
                </tr>

                <tr key={"detailIndex"}>
                  <td
                    className={`pb-11  text-start font-medium text-primary-400 ${stickyColClassName}`}
                    style={{
                      position: "sticky",
                      left: 0,
                      zIndex: 100,
                      width: "400px", // Fixed width for the first column
                    }}
                  >
                    <div className="w-[400px] text-12 font-normal leading-18 text-primary-400">
                      Quantity
                    </div>
                  </td>
                  {chargesData.columns
                    .filter((column: any) => {
                      return !column?.hide;
                    })
                    .map((column: any, colIndex: any) => (
                      <TableData
                        className=" text-12  font-normal leading-18 text-primary-400"
                        key={colIndex}
                        value={`x ${column?.quantity}`}
                      />
                    ))}
                </tr>
                {/* Border */}
                <tr className="border-b border-[#AFB6D1]">
                  <div></div>
                </tr>
                {/* *** */}
              </tbody>
            </table>
          </div>
          <OneTimeFee
            isEditMode={isEditMode}
            chargesData={chargesData}
            setChargesData={setChargesData}
            oneTimeCharges={oneTimeCharges}
            setOneTimeCharges={setOneTimeCharges}
            defaultEditable={defaultEditable}
            initialOneTimeCharges={initialOneTimeCharges}
          />
          <div className="my-10 flex justify-end gap-x-8">
            <div className="flex flex-col ">
              <h3 className="text-12 font-medium leading-20 text-primary-600">
                All in rate
              </h3>
              <h3 className="text-16 font-semibold text-primary-500-base">
                Total Price
              </h3>
            </div>
            <div className="flex  items-end">
              <h3 className="text-18  font-semibold text-primary-500-base">
                EUR {total}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChargesTableFclComponent;
