import { objectToQueryParams } from "src/helpers";
import { IResponse } from "../../types";
import { rootApi } from "src/services/api";
import { ENDPOINTS } from "src/store/endpoints";

const APIS = rootApi.injectEndpoints({
  endpoints: (build) => ({
    getLocations: build.mutation<IResponse, Partial<any>>({
      query: ({ endpoint, ...body }) => {
        return {
          url: endpoint + `?${objectToQueryParams(body)}`,
          method: "GET",
        };
      },
      transformResponse: (res: IResponse) => {
        return res;
      },
    }),

    checkLocalCharges: build.mutation<IResponse, Partial<any>>({
      query: (body) => {
        return {
          url:
            ENDPOINTS.COMMON.CHECK_LOCAL_CHARGES +
            `?${objectToQueryParams(body)}`,
          method: "GET",
        };
      },
      transformResponse: (res: IResponse) => {
        return res?.data;
      },
    }),
  }),
});
export const { useGetLocationsMutation, useCheckLocalChargesMutation } = APIS;
