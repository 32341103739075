import React from "react";
import { CONTAINER_TYPES } from "src/pages/customer/quotations-2/elements/fcl-quotation/container-type/container-type.component";

const CargoDetailsFcl = ({ cargoSpecs }: { cargoSpecs: any[] }) => {
  // Function to get the container label from CONTAINER_TYPES
  const getContainerLabel = (containerType: string) => {
    for (const type of CONTAINER_TYPES) {
      const matchedOption = type?.options.find(
        (option) => option?.value === containerType,
      );
      if (matchedOption) {
        return matchedOption?.label;
      }
    }
    return <span>{containerType}</span>; // Fallback if no match is found
  };

  return (
    <div>
      {cargoSpecs?.map((spec) => (
        <div
          key={spec?.id}
          className="flex  justify-between whitespace-nowrap border-b p-2"
        >
          <span className="mr-20 flex items-center">
            {spec?.quantity}x {getContainerLabel(spec?.container_type)}
          </span>
          <span>{spec?.weight * spec?.quantity} kg</span>
        </div>
      ))}
    </div>
  );
};

export default CargoDetailsFcl;
