import React, { useEffect, useState } from "react";
import GeneralSetting from "../general-settings";
import Integration from "../integration";
import ChangePassword from "../change-password";
import McsCustomTabs from "src/components/molecules/mcs-custom-tabs";
import { useLocation, useNavigate } from "react-router-dom";
import { TABS } from "./types";
import Carriers from "../carriers";

const tabs = [
  {
    label: "General Settings",
    children: <GeneralSetting />,
  },
  {
    label: "Integration",
    children: <Integration />,
  },
  {
    label: "Carriers",
    children: <Carriers />,
  },
  {
    label: "Change Password",
    children: <ChangePassword />,
  },
];

const SettingsTabsComponent = ({ tabChanged }: any) => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("0");
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tabValue = queryParams.get("tab") ?? TABS.SETTINGS.value;

    if (tabValue?.toLowerCase() === TABS.SETTINGS.value.toLowerCase()) {
      setActiveTab(TABS.SETTINGS.tab);
    } else if (
      tabValue?.toLowerCase() === TABS.INTEGRATION.value.toLowerCase()
    ) {
      setActiveTab(TABS.INTEGRATION.tab);
    } else if (tabValue?.toLowerCase() === TABS.PASSWORD.value.toLowerCase()) {
      setActiveTab(TABS.PASSWORD.tab);
    }
    navigate(`/admin/settings?tab=${tabValue}`);
  }, [location.search]);

  const handleChange = (index: any) => {
    setActiveTab(index.toString());
    const selectedTab = tabs[index];
    tabChanged(selectedTab.label);
    navigate(`/admin/settings?tab=${selectedTab.label}`);
  };

  return (
    <McsCustomTabs
      tabs={tabs}
      onChange={handleChange}
      defaultActiveKey={activeTab}
      className="setting-from"
    />
  );
};

export default SettingsTabsComponent;
