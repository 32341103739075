export interface FAQInterface {
  id: string;
  title: string;
  description: string;
}

export const FAQList: FAQInterface[] = [
  {
    id: "faq-1",
    title: "What services do you provide?",
    description:
      "We specialize in comprehensive freight forwarding services, covering air, ocean, and ground transportation. Our services include handling logistics from the quote process through to delivery, ensuring efficient and secure transport of your goods across global routes. In our myMCS platform progressively all above mentioned services will be provided to an one-stop solution for your transportation needs. ",
  },
  {
    id: "faq-2",
    title: "How do I get a quote for my shipment?",
    description:
      "Our user-friendly instant quotation system allows you to quickly receive a price estimate for your shipment's details. Enter the necessary details about your shipment, including origin, destination, and cargo specifics, and our system will instantly provide a competitive quote tailored to your needs",
  },
  {
    id: "faq-3",
    title: "What details are needed to obtain an accurate quote?",
    description:
      "For the most precise quotation, please provide the pickup and delivery locations, the type and quantity of goods being shipped, total weight, volume. This information helps us calculate the best possible rate for your shipment.",
  },
  {
    id: "faq-4",
    title: "Can I book a shipment directly on your platform?",
    description:
      "Absolutely! Once you receive a quote that meets your needs, you can book your shipment directly through our platform to our responsible team. Manage and upload any kind of documentation and necessary details concerning the shipper, consignee and their purchase orders. Our teams will be informed instantly and start handling your shipment, informing you on every step.",
  },
  {
    id: "faq-5",
    title: "How are shipping costs determined?",
    description:
      "The shipping costs are calculated based on factors such as the selected transportation mode, cargo weight and volume, the total distance to be covered, and any additional services like packing, insurance, or expedited delivery options.",
  },
  {
    id: "faq-6",
    title: "What modes of transportation can I choose from?",
    description:
      "MyMCS currently supports both LCL and FCL services. In the near future, you will also be able to select from air freight for rapid delivery or ground transportation for cost-effective continental travel. Each mode offers different benefits depending on your budget, timing, and cargo requirements.",
  },
  {
    id: "faq-7",
    title: "What are typical transit times for shipments?",
    description:
      "Transit times vary widely depending on the transport mode and route. Air freight is generally the fastest, with most shipments delivered within days, while ocean freight can take several weeks, depending on the distance and sailing schedules. Ground transport times will depend on the route complexity and distance. You'll receive an estimated transit time with your quote to help you plan accordingly.",
  },
  {
    id: "faq-8",
    title: "How can I ensure my shipment is protected?",
    description:
      "We recommend purchasing insurance coverage during the booking process. This coverage will protect your shipment against potential loss or damage during transit. Our team can help you understand the various options and ensure you choose the best protection for your goods.",
  },
  {
    id: "faq-9",
    title: "What should I do if there is an issue with my shipment?",
    description:
      "Please contact our support team as soon as you notice any discrepancies or issues. We are committed to providing 24/7 support to resolve any challenges swiftly and ensure your satisfaction with our services.",
  },
  {
    id: "faq-10",
    title: "How can I modify or cancel a shipment booking?",
    description:
      "If you need to modify or cancel a booking, you can do so by contacting our customer support team directly. Please note that changes or cancellations may be subject to certain terms and conditions, which will be detailed at the time of booking.",
  },
];
