import React, { useEffect, useState } from "react";
import { columns } from "./columns";
import usePagination from "src/hooks/usePagination";
import CustomDatatable from "src/components/organisms/datatable/elements";
import CustomButton from "src/components/atoms/button";
import { Icon } from "src/components/atoms/icons";
import ConfirmDeleteComponent from "src/components/organisms/confirm-delete/confirm-delete.component";

import useLoadingError from "src/hooks/useLoadingError";
import { showToast } from "src/utils";
import { TOASTR_TYPES } from "src/components/atoms/toast-container/types";
import {
  useDeleteCarrierMutation,
  useGetCarriersListMutation,
  useSyncCarriersMutation,
} from "src/services/api-service/carriers";

const Datatable = () => {
  const [reFetchData, setReFetchData] = useState(false);

  const { loading, startLoading, stopLoading } = useLoadingError();
  const [updateId, setUpdateId] = useState("");

  const [getCarriers] = useGetCarriersListMutation<any>();
  const [deleteCarrier] = useDeleteCarrierMutation<any>();
  const [syncCarriers] = useSyncCarriersMutation<any>();

  const {
    pagination,
    setPagination,
    handlePageChange,
    handleItemsPerPageChange,
    data,
    handleSearch,
    clearSearch,
    fetchData,
  } = usePagination({
    key: "carriers-datatable",
    mutation: getCarriers,
  });

  const handleOpenDeleteModal = (id: any) => {
    setUpdateId(id);
    setDeleteModalOpen(true);
  };

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const handleConfirmDelete = () => {
    const body = {
      id: updateId,
    };
    try {
      startLoading();
      updateId &&
        deleteCarrier(body)
          .unwrap()
          .then((data: any) => {
            console.log(data);
            showToast(
              TOASTR_TYPES.SUCCESS,
              data?.message || data?.data?.message || data?.original?.message,
            );
            fetchData({ currentPage: pagination?.currentPage, query: "" });
          });
    } finally {
      stopLoading();
    }
    setDeleteModalOpen(false);
  };

  const handleSyncCarriers = () => {
    startLoading();
    syncCarriers({})
      .unwrap()
      .then((data: any) => {
        console.log(data);
        showToast(
          TOASTR_TYPES.SUCCESS,
          data?.message || data?.data?.message || data?.original?.message,
        );
        fetchData({ currentPage: pagination?.currentPage, query: "" });
      })
      .finally(() => {
        stopLoading();
      });
  };

  const [columnDefs] = useState<any[]>(columns({ handleOpenDeleteModal }));

  useEffect(() => {
    reFetchData &&
      fetchData({ currentPage: pagination?.currentPage, query: "" });
  }, [reFetchData]);

  return (
    <>
      <CustomDatatable
        title={"Carriers"}
        description={`${pagination.total} Total Carriers${pagination.total > 1 ? "s" : ""}`}
        className="date-picker"
        data={data}
        columns={columnDefs}
        showHeader={true}
        showFooter={true}
        pagination={pagination}
        setPagination={setPagination}
        placeholder="Search by name"
        handleSearch={handleSearch}
        clearSearch={clearSearch}
        handlePageChange={handlePageChange}
        handleItemsPerPageChange={handleItemsPerPageChange}
        rowDraggable={false}
        button={
          <div className="flex items-center">
            <CustomButton
              variant="primary-icon-btn"
              disabled={loading}
              onClick={handleSyncCarriers}
            >
              <div className="flex items-center gap-2">
                <i className="shrink-0">
                  <Icon.McsIcSync />
                </i>
                <span>{loading ? "Sync In Progress" : "Sync Carriers"}</span>
              </div>
            </CustomButton>
          </div>
        }
      />
      {/* Delete modal */}
      <ConfirmDeleteComponent
        title="Are you sure you want to delete this carrier?"
        message="You can always show this carrier again. Just sync carriers for this."
        isOpen={isDeleteModalOpen}
        toggle={handleCloseDeleteModal}
        onConfirm={handleConfirmDelete}
        onCancel={handleCloseDeleteModal}
        className="custom-delete-modal"
      />
    </>
  );
};

export default Datatable;
