import React from "react";
import TextInput from "src/components/atoms/text-input";
import { formatToTwoDecimalPoints } from "src/helpers";

const TableDataComponent = ({
  value,
  chargeId,
  subChargeId,
  inputIndex,
  className = "",
  editable,
  onChange,
  formatDecimalPoints,
}: any) => {
  return (
    <td
      className={`text-end font-inter text-12 font-normal leading-18 text-primary-400 `}
    >
      <div className="flex justify-end">
        <div
          className={`ext-12 flex w-[120px]  justify-center  leading-18 ${className}`}
        >
          {editable ? (
            <TextInput
              type="number"
              value={value}
              required
              name=""
              placeholder="0.00"
              classes={{
                inputClassName: "normal-input ",
                containerClassName: "px-3",
              }}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                const key = e.key;

                // Prevent '+' and '-' signs
                if (key === "+" || key === "-") {
                  e.preventDefault(); // Blocks the input from being processed
                }
              }}
              onChange={(e: any) => {
                // Remove non-numeric characters except the decimal point
                e.preventDefault();
                let inputValue = e.target.value.replace(/[^0-9.]/g, "");

                // Ensure only one decimal point
                const parts = inputValue.split(".");
                if (parts.length > 2) {
                  inputValue = `${parts[0]}.${parts[1]}`;
                }

                // Format to two decimal places
                if (inputValue) {
                  const numericValue = parseFloat(inputValue);
                  e.target.value = numericValue.toFixed(2); // Ensure two decimals
                }
                onChange({
                  value: e.target.value,
                  chargeId,
                  subChargeId,
                  inputIndex,
                });
              }}
            />
          ) : formatDecimalPoints ? (
            value == 0 ? (
              "-"
            ) : (
              formatToTwoDecimalPoints(value)
            )
          ) : value == 0 ? (
            "-"
          ) : (
            value
          )}
        </div>
      </div>
    </td>
  );
};

export default TableDataComponent;
