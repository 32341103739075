export const ENDPOINTS = {
  ADMIN: {
    LOGIN: "/api/login",
    FORGOT_PASSWORD: "/api/forgot-password",
    VERIFY_OTP: "/api/check-otp",
    RESET_PASSWORD: "/api/update-password",
    VERIFY_RECAPTCHA: "/api/recaptcha",

    CHECK_EMAIL: "/auth/check-email",
    CHECK_EMAIL_EXISTS: "/api/email-already",
    CHECK_PHONE: "/auth/check-phone",
    CHECK_ALREADY_EXISTS: "/auth/check-already-exists",
    GET_PROFILE: "/auth/profile",

    GET_CURRENT_USER: "/api/user",
    UPDATE_CURRENT_USER: "/api/user/update",
    CHANGE_PASSWORD: "/api/user/update-password",

    ADMIN_LIST: "/api/admin-user",
    ADMIN_BY_ID: "/api/adminusers",
    ADD_ADMIN: "/api/insert-admin-user",
    UPDATE_ADMIN: "/api/adminusers",
    DELETE_ADMIN: "/api/adminusers",

    PRICES_LIST: "/api/pricing/list",
    PRICES_EXCEL: "/api/pricing-excel",
    CRUD_PRICE: "/api/pricing",
    DELETE_PRICE_SHEET: "/api/pricing-excel",
    IMPORT_PRICE_SHEET: "/api/pricing-excel/import",
    DOWNLOAD_PRICE: "/api/pricing/download",
    DOWNLOAD_PRICE_SHEET: "/pricingsheets",

    LOCAL_CHARGES: "/api/local-charges/list",
    LOCAL_EXCEL_CHARGES: "/api/local-charges-excel",
    CRUD_LOCAL_CHARGES: "/api/local-charges",
    DOWNLOAD_LOCAL_CHARGES: "/api/local-charges/download",

    TRUCKING_LIST: "/api/trucking-charges/list",
    TRUCKING_SHEET_LIST: "/api/trucking-excel",
    CRUD_TRUCKING: "/api/trucking-charges",
    IMPORT_TRUCKING_SHEET: "/api/pricing-excel/import",
    DELETE_TRUCKING_SHEET: "/api/trucking-excel",
    DOWNLOAD_TRUCKING: "/api/trucking-charges/download",

    MARGIN_GROUPS_LIST: "/api/margin-group/list",
    MARGIN_GROUP_BY_ID: "/api/margin-group",
    ADD_MARGIN_GROUP: "/api/margin-group",
    UPDATE_MARGIN_GROUP: "/api/margin-group/update",
    DELETE_MARGIN_GROUP: "/api/margin-group",
    DOWNLOAD_MARGIN_GROUP: "/api/margin-group/download-pdf",

    COST_GROUPS_LIST: "api/margin-group",
    ADD_COST_GROUP: "/api/margin-group",
    DELETE_COST_GROUP: "/api/margin-group",
    DOWNLOAD_COST_GROUP: "/api/margin-group",

    USERS_LIST: "/api/customer/list",
    USER_BY_ID: "/api/customer",
    ADD_USER: "/api/customer",
    UPDATE_USER: "/api/customer/update",
    DELETE_USER: "/api/customer",
    USER_NAMES_LIST: "api/customer/getCustomerNamesAndIds",

    CUSTOMER_USERS_LIST: "/api/customer-users/list",
    CUSTOMER_USER_BY_ID: "/api/customer-users",
    ADD_CUSTOMER_USER: "/api/customer-users",
    UPDATE_CUSTOMER_USER: "/api/customer-users",
    DELETE_CUSTOMER_USER: "/api/customer-users",
    DOWNLOAD_CUSTOMER_USER: "/api/customer-users",
    CUSTOMER_QUOTATIONS: "/api/customer",
    CUSTOMER_BOOKINGS: "/api/customer",
    CUSTOMER_QUOTATIONS_ALL: "/api/customer/list-all-quotes",
    EXPORT_ALL_CUSTOMERS: "/api/customer/all-customers",

    ALL_USERS_LISTING: "/api/all-users",
    EXPORT_ALL_USERS: "/api/all-users-for-pdf",
    APPROVE_USER: "/api/accept",
    REJECT_USER: "/api/reject",

    GENERATE_QUOTATION: "/api/quotation",
    DELETE_QUOTATION: "/api/quotation",
    SEND_QUOTATION_VIA_EMAIL: "api/quotation/send-internal-quotation-email/",
    SEND_QUOTATION_EMAIL_BY_IDS:
      "/api/quotation/send-internal-quotation-email-by-ids",
    GENERATE_INTERNAL_QUOTATION: "/api/process-quotation-calculation",
    PROCESS_INDIVIDUAL_QUOTATION_CALCULATION:
      "/api/process-individual-quotation-calculation",
    GET_QUOTATION: "/api/quotation",
    UPDATE_STATUS: "api/quotation/",
    DOWNLOAD_CUSTOM_QUOTATION: "/api/quotation/",
    DOWNLOAD_INTERNAL_QUOTATION: "/api/quotations-pdf/",
    DOWNLOAD_QUOTATION_HISTORY: "/api/main-quotation-pdf/",
    DOWNLOAD_BOOKING: "/api/quotation/sub/",
    DETAILS_INTERNAL_QUOTATION: "/api/main-quotation-detail",
    UPDATE_PRICE: "/api/quotation/update-charges",
    UPDATE_PRICE_FCL: "/api/quotation/update-charges-fcl",

    UPLOAD_FILE: "/api/image-upload",

    INTEGRATION_STORE_CREDS: "/api/store-credentials",
    INTEGRATION_GET_TOKEN: "/api/get-token",

    COUNTRIES_LIST: "/api/countries",
    PORTS_LIST: "/api/port-list-via-country",

    ORIGIN_COUNTRIES: "/api/origin-countries",
    ORIGIN_PORTS_BY_COUNTRY: "/api/origin-port-by-country",
    DESTINATION_COUNTRIES_BY_PORTS: "/api/destination-countries",
    DESTINATION_PORTS: "/api/destination-ports",

    GET_ALL_PORTS: "/api/all-ports",
    GET_AREANAMES_FCL: "/api/areanames",
    POSTCODE_LIST: "/api/postcodes",

    // Carriers
    GET_CARRIERS: "/api/carriers",
    DELETE_CARRIER: "/api/carriers",
    SYNC_CARRIERS: "/api/carriers/sync",
    UPDATE_CARRIER_STATUS: "/api/carriers/update-status",
  },

  COMMON: {
    CHECK_LOCAL_CHARGES: "/api/local-charges",
    GET_ALL_CARRIERS: "/api/all-carriers",
  },

  CUSTOMER: {
    LOGIN: "/api/customer_user/login",
    SIGNUP: "/api/customer_user/register",
    FORGOT_PASSWORD: "/api/customer_user/forgot-password",
    VERIFY_OTP: "/api/customer_user/check-otp",
    RESET_PASSWORD: "/api/customer_user/update-password",
    VERIFY_RECAPTCHA: "/api/recaptcha",
    GET_BOOKING: "api/customer_user/bookings",
    CONFIRM_QUOTATION_FORM: "/api/customer_user/quotations",
    SUB_QUOTATION: "/api/quotation/sub",
    GET_QUOTATION: "api/customer/quotations",
  },
};
