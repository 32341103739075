import React from "react";
import "./style.css";
import { findIconByValue } from "src/utils";

const QuotationHorizontalStepsFclComponent = ({
  value,
  transhipMentCount,
  data,
  terms,
}: {
  value: any;
  transhipMentCount?: any;
  data: any;
  terms: any;
}) => {
  const oceanIcon = findIconByValue("ocean")?.icon;
  const roadIcon = findIconByValue("road")?.icon;

  return (
    <ol className="col-span-4 mt-20 flex w-full flex-col items-center justify-center">
      <div className="flex w-full items-center justify-center">
        {["1", "3"].includes(terms) && (
          <li
            key={"index"}
            className="relative flex w-[70px] items-center after:inline-block after:h-[1px] after:w-full after:border-b after:border-dotted after:border-[#808CB7] after:content-['']"
          >
            <span className="absolute left-[65%] top-[-20px] -translate-x-1/2 transform">
              {roadIcon}
            </span>
            <img src="/assets/icons/elipse-icon.svg" alt="elipse" />
          </li>
        )}

        {Array.from({ length: transhipMentCount }, (_, index) => {
          // Perform your logic here
          return (
            <li
              key={index}
              className="relative flex w-[70px] items-center after:inline-block after:h-[1px] after:w-full after:border-b after:border-dotted after:border-[#808CB7] after:content-['']"
            >
              <span className="absolute left-[65%] top-[-20px] -translate-x-1/2 transform">
                {oceanIcon}
              </span>
              <img src="/assets/icons/elipse-icon.svg" alt="elipse" />
            </li>
          );
        })}

        {["3", "4"].includes(terms) && (
          <>
            <li
              key={"index434"}
              className="relative flex w-[70px] items-center after:inline-block after:h-[1px] after:w-full after:border-b after:border-dotted after:border-[#808CB7] after:content-['']"
            >
              <span className="absolute left-[65%] top-[-20px] -translate-x-1/2 transform">
                {roadIcon}
              </span>
              <img src="/assets/icons/elipse-icon.svg" alt="elipse" />
            </li>
          </>
        )}

        <li className="flex flex-shrink-0 items-center">
          <img src="/assets/icons/elipse-icon.svg" alt="elipse" />
        </li>
      </div>
      <div className="text-12 font-normal leading-18 text-gray-600">
        {value} Days
      </div>
    </ol>
  );
};

export default QuotationHorizontalStepsFclComponent;
