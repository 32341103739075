import React from "react";
import Datatable from "./elements/datatable";
import ContentWrapper from "src/components/layouts/content-wrapper";
const Carriers = () => {
  return (
    <ContentWrapper>
      <Datatable />
    </ContentWrapper>
  );
};

export default Carriers;
