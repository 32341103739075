import React, { useEffect, useState } from "react";
import CustomDatePicker from "src/components/atoms/date-picker";

import TextInput from "src/components/atoms/text-input";
import { useQuotationsContext } from "src/context/quotations-context";
import CargoSpecFcl from "./cargo-specs-fcl";
import { useQuotationsContextFcl } from "src/context/quotations-context-fcl";
import LazyPortsSelector from "src/components/atoms/lazy-port-selecter";
import TermsInputFcl from "./terms-input-fcl";
import ChargesTableFcl from "src/components/molecules/fcl/charges-table-fcl";
import {
  checkHasValidQuotationItems,
  formatContainerChargesWithTerms,
  formatOneTimeChargesWithTerms,
  isCustomQuotationUpdated,
} from "src/helpers";
import { quotationsSelector } from "src/store/features/quotations/selectors";
import { useAppSelector } from "src/store/hook";
import LazyAreanameSelector from "src/components/atoms/lazy-areaname-selecter";
import useQueryParams from "src/hooks/useQueryParams";

const FclQuotationComponent = () => {
  const { getQueryParam } = useQueryParams();

  const id = getQueryParam("id");

  const { data } = useAppSelector(quotationsSelector);

  const { formikQuotation } = useQuotationsContext();
  const { formikQuotationFcl } = useQuotationsContextFcl();

  const [oneTimeCharges, setOneTimeCharges] = useState<any>(
    formikQuotationFcl?.values?.chargesData?.one_time_charges,
  );

  const [chargesData, setChargesData] = useState<any>(
    formikQuotationFcl?.values?.chargesData,
  );

  useEffect(() => {
    formikQuotationFcl.setFieldValue("chargesData", {
      ...chargesData,
      one_time_charges: oneTimeCharges,
    });
  }, [chargesData, oneTimeCharges]);

  useEffect(() => {
    const terms = formikQuotationFcl.values.terms;

    setChargesData((prev: any) => ({
      ...prev,
      charges: formatContainerChargesWithTerms(prev.charges, terms), // per container charges will be settled here
    }));

    setOneTimeCharges((prev: any) =>
      formatOneTimeChargesWithTerms(prev, terms),
    ); // one time charges will be settled here
  }, [formikQuotationFcl.values.terms]);

  useEffect(() => {
    const cargoItemSpecs: any[] = formikQuotationFcl.values.cargo_item_specs;

    if (cargoItemSpecs?.length > 0) {
      // Extract container types
      const containerTypes: any[] = cargoItemSpecs
        .filter((item: any) => item.container_type)
        .map((item: any) => ({
          container_type: item.container_type,
          quantity: item?.quantity,
        }));

      // Update chargesData columns and synchronize values
      setChargesData((prevChargesData: any) => {
        const updatedColumns: any[] = containerTypes; // Set columns to container types
        const updatedCharges: any[] = prevChargesData.charges.map(
          (charge: any) => ({
            ...charge,
            details: charge.details.map((detail: any) => ({
              ...detail,
              values: updatedColumns.map(
                (_, index) => detail.values[index] || "",
              ), // Ensure values match columns
            })),
          }),
        );

        return {
          ...prevChargesData,
          columns: updatedColumns,
          charges: updatedCharges,
        };
      });
    }
  }, [formikQuotationFcl.values.cargo_item_specs]);

  const clearLocations = () => {
    formikQuotationFcl.setFieldValue("pickup_location", "");
    formikQuotationFcl.setFieldValue("destination_location", "");
  };

  useEffect(() => {
    if (
      !formikQuotationFcl.values.origin_port ||
      !formikQuotationFcl.values.destination_port
    ) {
      formikQuotationFcl?.setFieldValue("terms", "2");
    }
  }, [
    formikQuotationFcl.values.origin_port,
    formikQuotationFcl.values.destination_port,
  ]);

  return (
    <>
      <span className="hidden"> Routing Details </span>
      <div className="mb-24 px-24 pb-16">
        <h3 className="pb-12 text-18 font-semibold leading-28 text-primary-800">
          Routing Details
        </h3>
        <div className="grid grid-cols-12 gap-x-5">
          <div className="col-span-12 xl:col-span-5">
            <TextInput
              name="reference_no"
              type="text"
              label="Reference No."
              placeholder="Type here.."
              classes={{
                inputClassName: "normal-input",
              }}
              value={
                formikQuotationFcl?.values?.reference_no ||
                formikQuotation?.value?.reference_no
              }
              onChange={(e: any) => {
                formikQuotation?.setFieldValue("reference_no", e.target.value);
                formikQuotationFcl?.setFieldValue(
                  "reference_no",
                  e.target.value,
                );
              }}
            />
          </div>
          <div className="col-span-12 xl:col-span-5">
            <CustomDatePicker
              name="date"
              label="Cargo Ready date"
              containerClassName="cargo-ready-date-picker"
              value={
                formikQuotation?.values?.date ||
                formikQuotationFcl?.values?.date
              }
              onChange={(date: any, dateString: any) => {
                formikQuotation?.setFieldValue("date", date);
                formikQuotationFcl?.setFieldValue("date", date);
              }}
              errorMessage={
                formikQuotation?.errors?.date ||
                formikQuotationFcl?.errors?.date
              }
              showError={
                formikQuotation?.touched?.date ||
                formikQuotationFcl?.touched?.date
              }
              required={true}
            />
          </div>
        </div>
      </div>

      {/* Port to port */}
      <div className="mb-24 grid grid-cols-12 gap-5 px-24 pb-16">
        {/*  Origin Port & Destination Port & Terms */}
        <div className="col-span-12 xl:col-span-4">
          <LazyPortsSelector
            placeholder={
              isCustomQuotationUpdated(formikQuotation)
                ? "Enter a Port"
                : "Select a Port"
            }
            name="origin_port"
            label="Origin Port"
            value={formikQuotationFcl?.values?.origin_port}
            onChange={(value: string) => {
              isCustomQuotationUpdated(formikQuotation) &&
                formikQuotationFcl?.setFieldValue("origin_port", value);
            }}
            onSelect={(value: any) => {
              formikQuotationFcl?.setFieldValue("origin_port", value?.value);
              formikQuotationFcl?.setFieldValue(
                "source_port_name",
                value?.label,
              );
              clearLocations();
            }}
            required={true}
            allowCustomValue={isCustomQuotationUpdated(formikQuotation)}
            filterValue={formikQuotationFcl?.values?.destination_port}
            initialSearchValue={data?.sourcePortName}
          />
        </div>
        <div className="col-span-12 xl:col-span-4">
          <LazyPortsSelector
            placeholder={
              isCustomQuotationUpdated(formikQuotation)
                ? "Enter a Port"
                : "Select a Port"
            }
            name="destination_port"
            label="Destination Port"
            value={formikQuotationFcl?.values?.destination_port}
            onChange={(value: string) => {
              isCustomQuotationUpdated(formikQuotation) &&
                formikQuotationFcl?.setFieldValue("destination_port", value);
            }}
            onSelect={(value: any) => {
              formikQuotationFcl?.setFieldValue(
                "destination_port",
                value?.value,
              );
              formikQuotationFcl?.setFieldValue(
                "destination_port_name",
                value?.label,
              );
              clearLocations();
            }}
            required={true}
            allowCustomValue={isCustomQuotationUpdated(formikQuotation)}
            filterValue={formikQuotationFcl?.values?.origin_port}
            initialSearchValue={data?.destinationPortName}
          />
        </div>
        {formikQuotationFcl?.values?.quotation_type === "custom" ? (
          <div className="col-span-12 xl:col-span-4">
            <TermsInputFcl />
          </div>
        ) : (
          formikQuotationFcl?.values?.origin_port &&
          formikQuotationFcl?.values?.destination_port && (
            <div className="col-span-12 xl:col-span-4">
              <TermsInputFcl />
            </div>
          )
        )}
      </div>

      {/* Door to port */}
      {formikQuotationFcl?.values?.terms?.includes("1") && (
        <div className="mb-24 grid grid-cols-12 gap-5 px-24 pb-16">
          <div className="col-span-12 xl:col-span-4">
            <LazyAreanameSelector
              placeholder="Enter Pickup Location"
              name="pickup_location"
              label="Pickup Location"
              value={formikQuotationFcl?.values?.pickup_location}
              onChange={(value: string) => {
                isCustomQuotationUpdated(formikQuotation) &&
                  formikQuotationFcl.setFieldValue("pickup_location", value);
              }}
              onSelect={(value: any) => {
                formikQuotationFcl.setFieldValue(
                  "pickup_location",
                  value?.value,
                );
              }}
              port={formikQuotationFcl?.values?.origin_port}
              disabled={!Boolean(formikQuotationFcl.values.origin_port)}
              required={true}
              allowCustomValue={isCustomQuotationUpdated(formikQuotation)}
            />
          </div>
        </div>
      )}

      {/* Door to door */}
      {formikQuotationFcl?.values?.terms?.includes("3") && (
        <div className="mb-24 grid grid-cols-12 gap-5 px-24 pb-16">
          <div className="col-span-12 xl:col-span-4">
            <LazyAreanameSelector
              placeholder="Enter Pickup Location"
              name="pickup_location"
              label="Pickup Location"
              value={formikQuotationFcl?.values?.pickup_location}
              onChange={(value: string) => {
                isCustomQuotationUpdated(formikQuotation) &&
                  formikQuotationFcl.setFieldValue("pickup_location", value);
              }}
              port={formikQuotationFcl?.values?.origin_port}
              onSelect={(value: any) => {
                formikQuotationFcl.setFieldValue(
                  "pickup_location",
                  value?.value,
                );
              }}
              disabled={!Boolean(formikQuotationFcl.values.origin_port)}
              required={true}
              allowCustomValue={isCustomQuotationUpdated(formikQuotation)}
            />
          </div>
          <div className="col-span-12 xl:col-span-4">
            <LazyAreanameSelector
              placeholder="Enter Destination Location"
              name="destination_location"
              label="Destination Location"
              value={formikQuotationFcl?.values?.destination_location}
              onChange={(value: string) => {
                isCustomQuotationUpdated(formikQuotation) &&
                  formikQuotationFcl.setFieldValue(
                    "destination_location",
                    value,
                  );
              }}
              onSelect={(value: any) => {
                formikQuotationFcl.setFieldValue(
                  "destination_location",
                  value?.value,
                );
              }}
              port={formikQuotationFcl?.values?.destination_port}
              disabled={!Boolean(formikQuotationFcl.values.destination_port)}
              required={true}
              allowCustomValue={isCustomQuotationUpdated(formikQuotation)}
            />
          </div>
        </div>
      )}

      {/* Port to door */}
      {formikQuotationFcl?.values?.terms?.includes("4") && (
        <div className="mb-24 grid grid-cols-12 gap-5 px-24 pb-16">
          <div className="col-span-12 xl:col-span-4"></div>
          <div className="col-span-12 xl:col-span-4">
            <LazyAreanameSelector
              placeholder="Enter Destination Location"
              name="destination_location"
              label="Destination Location"
              value={formikQuotationFcl?.values?.destination_location}
              onChange={(value: any) => {
                isCustomQuotationUpdated(formikQuotation) &&
                  formikQuotationFcl.setFieldValue(
                    "destination_location",
                    value,
                  );
              }}
              port={formikQuotationFcl?.values?.destination_port}
              onSelect={(value: any) => {
                formikQuotationFcl.setFieldValue(
                  "destination_location",
                  value?.value,
                );
              }}
              disabled={!Boolean(formikQuotationFcl.values.destination_port)}
              allowCustomValue={isCustomQuotationUpdated(formikQuotation)}
            />
          </div>
        </div>
      )}

      <div className="mb-24 border-t border-border px-24">
        <h2 className="my-12 text-18 font-semibold leading-28 text-primary-800">
          Container Type
        </h2>
        <CargoSpecFcl />

        {formikQuotation?.values?.quotation_type == "custom" &&
          checkHasValidQuotationItems(
            formikQuotationFcl?.values?.cargo_item_specs,
          ) && (
            <>
              <h1 className="my-16 text-24 font-semibold">Pricing Details</h1>
              <ChargesTableFcl
                chargesData={chargesData}
                setChargesData={setChargesData}
                showEditButton={false}
                defaultEditable={true}
                stickyColClassName="bg-[#FBFBFB]"
                isEditMode={true}
                oneTimeCharges={oneTimeCharges}
                setOneTimeCharges={setOneTimeCharges}
              />
            </>
          )}
      </div>
    </>
  );
};

export default FclQuotationComponent;
