import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Progress } from "antd";
import { AnimatePresence, motion } from "framer-motion";
import CarrierSlider from "src/components/atoms/carriers-slider";
import { CARRIERS } from "src/components/molecules/fcl/carrier-fcl/carrier-fcl.component";

const messages = [
  "Searching for the best deals...",
  "Comparing rates from top carriers...",
  "Optimizing routes for cost savings...",
  "Ensuring fast and reliable options...",
  "Finalizing the best quotations...",
];

const extendedMessages = [
  "It's taking a little longer than expected, but we're working hard to find you the best options!",
  "Hang tight! We are checking additional carriers to get you the most competitive rates.",
  "Good things take time! Your best options are being prepared.",
];

const CargoShipperFclComponent = () => {
  const [progress, setProgress] = useState(0);
  const [loadingTextIndex, setLoadingTextIndex] = useState(0);
  const [showExtendedMessage, setShowExtendedMessage] = useState(false);
  const [currentCarrierIndex, setCurrentCarrierIndex] = useState(0);
  const [extendedMessageIndex, setExtendedMessageIndex] = useState(0);

  useEffect(() => {
    const totalDuration = 180; // Total time in seconds (3 minutes)
    let elapsedTime = 0;

    const progressInterval = setInterval(() => {
      elapsedTime += 1;

      setProgress((prevProgress) => {
        const fastPhase = 1; // Fast increase for the first 60s
        const slowPhase = totalDuration - fastPhase; // Remaining time

        let increment;
        if (elapsedTime < fastPhase) {
          // Fast growth in the first minute
          increment = 90 / fastPhase; // 60% increase in first 60s
        } else {
          // Slower growth for the next 2 minutes
          increment = 10 / slowPhase; // 40% increase in last 120s
        }

        return Math.min(100, prevProgress + increment);
      });
    }, 1000); // Update every second

    const textInterval = setInterval(() => {
      setLoadingTextIndex((prev) => (prev + 1) % messages.length);
    }, 15000); // Change message every 15 seconds

    const carrierInterval = setInterval(() => {
      setCurrentCarrierIndex((prev) => (prev + 1) % CARRIERS.length);
    }, 3000); // Change carrier image every 3 seconds

    setTimeout(() => {
      setShowExtendedMessage(true);
    }, 60000); // Show extended message after 1 min

    const extendedMessageInterval = setInterval(() => {
      setExtendedMessageIndex((prev) => (prev + 1) % extendedMessages.length);
    }, 6000); // Change extended message every 3 seconds

    return () => {
      clearInterval(progressInterval);
      clearInterval(textInterval);
      clearInterval(carrierInterval);
      clearInterval(extendedMessageInterval);
    };
  }, []);

  return (
    <div className="center mt-4 flex flex-col items-center justify-center">
      {/* Animated Carrier Logo */}
      <div className="relative flex h-[100px] w-[100px] items-center justify-center overflow-hidden">
        <AnimatePresence mode="wait">
          <motion.img
            key={CARRIERS[currentCarrierIndex].image}
            src={`../../../assets/images/carriers/${CARRIERS[currentCarrierIndex].image}`}
            alt="carrier"
            className="absolute h-[100px] w-[100px] object-contain"
            variants={{
              initial: { opacity: 0, y: 50 }, // Start below
              animate: { opacity: 1, y: 0 }, // Move to the center
              exit: { opacity: 0, y: -50 }, // Move up and fade out
            }}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={{ duration: 0.5, ease: "easeInOut" }}
          />
        </AnimatePresence>
      </div>

      {/* Dynamic Loading Messages */}
      <h1 className="mb-4 flex items-center px-6 text-center text-20 font-medium">
        <motion.img
          className="mr-8 animate-[spin_2s_linear_infinite]"
          src="/assets/images/loading-success.svg"
          alt="tick"
        />
        {messages[loadingTextIndex]}
      </h1>

      {/* Animated Progress Bar */}
      <Progress percent={Math.round(progress)} showInfo className="w-80" />

      {/* Extended message after 1 minute */}
      {showExtendedMessage && (
        <AnimatePresence mode="wait">
          <motion.h1
            key={extendedMessageIndex}
            className="my-4 px-6 text-center text-18 text-primary-600"
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
            transition={{ duration: 1, ease: "easeInOut" }}
          >
            {extendedMessages[extendedMessageIndex]}
          </motion.h1>
        </AnimatePresence>
      )}
      <div className="mt-6 flex flex-col justify-center bg-primary-50 px-60 text-center text-primary-400">
        <h1 className="my-36 text-24 font-semibold">
          Get the best deals in no time.
        </h1>

        {/* Features List */}
        <div className="flex justify-center">
          <div className="mb-20 flex flex-col items-start justify-center">
            {[
              "Get multiple quotations",
              "Simple and fast booking",
              "24/7 Customer support",
            ].map((text, i) => (
              <div key={i} className="mb-20 flex items-center">
                <img
                  className="mr-8"
                  src="/assets/images/sucess-tick.png"
                  alt="tick"
                />
                <h1 className="text-20 font-normal">{text}</h1>
              </div>
            ))}
          </div>
        </div>

        {/* Rotating Carrier Logos */}
        <CarrierSlider />

        {/* Partnership Text */}
        <h1 className="mb-13 text-black">
          In Partnership with{" "}
          <Link
            className="text-primary-400 underline decoration-1 underline-offset-4"
            to={""}
          >
            10+ Cargo Shippers
          </Link>
        </h1>
      </div>
    </div>
  );
};

export default CargoShipperFclComponent;
