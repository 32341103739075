import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import CustomDatePicker from "src/components/atoms/date-picker";
import { Icon } from "src/components/atoms/icons";
import LazyPortsSelector from "src/components/atoms/lazy-port-selecter";
import { useQuotationsContext } from "src/context/quotations-context";
import { makeFclQuotationPayload } from "src/helpers";
import useUser from "src/hooks/useUser";
import {
  useGenerateQuotationMutation,
  useGetAllCarriersMutation,
} from "src/services/api-service/quotations";
import { setLoadingFcl } from "src/store/features/quotations-fcl";
import { fclLoadingSelector } from "src/store/features/quotations-fcl/selectors";
import { quotationsSelector } from "src/store/features/quotations/selectors";
import { useAppSelector } from "src/store/hook";
import CargoDetailsFcl from "../cargo-details-fcl";

const QuotationResultFiltersFcl = () => {
  const [generateQuotationMutation] = useGenerateQuotationMutation<any>();
  const [getAllCarriers] = useGetAllCarriersMutation<any>();

  useEffect(() => {
    getAllCarriers({});
  }, []);

  const { formikQuotationFcl } = useQuotationsContext();

  const { data } = useAppSelector(quotationsSelector);

  const fclLoading = useAppSelector(fclLoadingSelector);

  const dispatch = useDispatch();

  const user = useUser();

  const [locations, setLocations] = useState({
    source_port: "",
    destination_port: "",
    source_location: "",
    destination_location: "",
  });

  useEffect(() => {
    setLocations((prev) => ({
      source_port: data?.source_port,
      destination_port: data?.destination_port,
      source_location: data?.source_location,
      destination_location: data?.destination_location,
    }));
  }, [data]);

  const swapLocations = () => {
    dispatch(setLoadingFcl(true));
    setLocations((prev) => {
      let payload = {
        ...data,
        user_id: user?.id,
        source_port: prev?.destination_port,
        destination_port: prev?.source_port,
        source_location: prev?.destination_location,
        destination_location: prev?.source_location,
      };

      payload = makeFclQuotationPayload(payload);

      generateQuotationMutation(payload)
        .unwrap()
        .then((res: any) => {
          // getDetails();
        })
        .catch((err) => {
          setLocations((prev) => ({
            ...prev,
            source_port: prev.destination_port,
            destination_port: prev.source_port,
          }));
        })
        .finally(() => {
          dispatch(setLoadingFcl(false));
        });

      return {
        ...prev,
        source_port: prev.destination_port,
        destination_port: prev.source_port,
      };
    });
  };

  console.log(locations, "Locations>>");

  return (
    <div className="flex flex-col gap-y-[10px]">
      <div className=" mt-10 flex w-full flex-wrap items-start justify-between gap-x-[20px] gap-y-2  rounded-[10px] border border-border  bg-primary-50 px-24 py-20">
        <div className="flex flex-[0.68] flex-col gap-y-[6px]">
          <span className="text-14 font-medium leading-20 text-gray-400">
            Routing Details
          </span>
          <div className="flex w-full flex-wrap gap-x-[20px]">
            <div className="flex flex-1 flex-col">
              <LazyPortsSelector
                placeholder="Select a Port"
                name="source_port"
                label=""
                value={locations?.source_port}
                onChange={(value: string) => {
                  formikQuotationFcl?.setFieldValue("source_port", value);
                }}
                onSelect={(value: string) => {
                  formikQuotationFcl?.setFieldValue("source_port", value);
                }}
                required={true}
                disabled={true}
                initialSearchValue={data?.sourcePortName}
              />
            </div>
            <div className=" flex flex-[0.2] items-center">
              <button
                type="button"
                className=""
                onClick={swapLocations}
                disabled={true}
              >
                <Icon.McsIcFiltersExchange />
              </button>
            </div>
            <div className="flex flex-1 flex-col gap-2">
              <div className="col-span-12 xl:col-span-4">
                <LazyPortsSelector
                  placeholder="Select a Port"
                  name="destination_port"
                  label=""
                  value={locations?.destination_port}
                  onChange={(value: string) => {
                    formikQuotationFcl?.setFieldValue(
                      "destination_port",
                      value,
                    );
                  }}
                  onSelect={(value: string) => {
                    formikQuotationFcl?.setFieldValue(
                      "destination_port",
                      value,
                    );
                  }}
                  required={true}
                  disabled={true}
                  initialSearchValue={data?.destinationPortName}
                />
              </div>
            </div>
            <div className="flex flex-[0.7] flex-col gap-2">
              <CustomDatePicker
                value={data?.date} // Static value
                name="date"
                label=""
                containerClassName=""
                className="data-limit-select w-full"
                disabled
              />
            </div>
          </div>
        </div>
        <div className="flex flex-[0.32] flex-col gap-y-[6px]">
          <span className="text-14 font-medium leading-20 text-gray-400">
            Cargo Details
          </span>
          <div className="min-h-[47px] rounded-lg border border-gray-300 bg-gray-200 px-12 py-8 text-gray-500-base">
            <CargoDetailsFcl cargoSpecs={data?.cargo_item_specs} />
          </div>
        </div>
      </div>

      <div className="flex items-center justify-center gap-x-2 rounded-[10px] border border-border bg-white py-[9px]">
        {fclLoading ? (
          <>
            <img
              src="../../assets/images/loading-results.gif"
              className="mr-[-20px]"
            />
            <p className="text-16  font-normal leading-28 text-gray-700">
              We are getting the best prices for you. This may take some time.
            </p>
          </>
        ) : (
          <>
            <Icon.McsIcCheckGreen />{" "}
            <p className="text-16  font-normal leading-28 text-gray-700">
              Search completed. We’ve gathered the best prices for you.
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default QuotationResultFiltersFcl;
