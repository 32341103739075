import React, { useEffect, useState } from "react";
import { Checkbox, CheckboxProps, Collapse, Select } from "antd";
import { Icon } from "src/components/atoms/icons";
import CustomButton from "src/components/atoms/button";
import CustomModal from "src/components/molecules/custom-modal";

import QuotationSelected from "src/pages/quotation-results/elements/quotation-selected";
import QuotationRoutesFcl from "../quotation-routes-fcl";
import AllInRateComponent from "src/components/atoms/all-in-rate/all-in-rate.component";
import ChargesTableFcl from "../charges-table-fcl";
import useValidCharges from "src/hooks/useValidCharges";
import { useAppDispatch, useAppSelector } from "src/store/hook";
import {
  quotationsSelector,
  sortBySelector,
} from "src/store/features/quotations/selectors";
import {
  setFilteredQuotations,
  setSelectedQuotations,
  setSortBy,
  updateQuotationCharges,
} from "src/store/features/quotations";
import CarrierFclComponent from "../carrier-fcl/carrier-fcl.component";

import { useNavigate } from "react-router-dom";
import useQueryParams from "src/hooks/useQueryParams";
import {
  addEditableFields,
  calculateFinalTotalPrice,
  getInitialOneTimeCharges,
  getTransShipmentCount,
  sortByQuotations,
} from "src/helpers";
import { fclLoadingSelector } from "src/store/features/quotations-fcl/selectors";
import { useUpdatePriceFclMutation } from "src/services/api-service/quotations";
import { showToast } from "src/utils";
import { TOASTR_TYPES } from "src/components/atoms/toast-container/types";
import { SORT_BY } from "src/types";
import CardLoader from "../../card-loader";

const chargesdata = {
  columns: [
    {
      container_type: "20DRY",
      quantity: "20",
      sub_total: 3521.3999999999996,
      unit_price: 176.07,
      hide: false,
    },
    {
      container_type: "40DRY",
      quantity: "10",
      sub_total: 1684.1999999999998,
      unit_price: 168.42,
      hide: false,
    },
    {
      container_type: "PER SHIPMENT",
      quantity: 1,
      sub_total: 0,
      unit_price: 0,
      hide: true,
    },
  ],
  charges: [
    {
      id: 1,
      category: "Freight",
      currency: "EUR",
      total: [176.07, 168.42, 0],
      details: [
        {
          id: 2,
          name: "Freight",
          charge_description: "Freight",
          values: [176.07, 168.42, 0],
          valueDetails: [
            {
              charge_id: 30103,
              value: 176.07,
              container_type: "20DRY",
              rate_basis: "PER_CONTAINER",
            },
            {
              charge_id: 30104,
              value: 168.42,
              container_type: "40DRY",
              rate_basis: "PER_CONTAINER",
            },
          ],
        },
      ],
    },
  ],
  total_price: 5205.599999999999,
};

const QuotationResultsCardFcl = ({ isAdmin }: { isAdmin: boolean }) => {
  const [updatePriceFcl] = useUpdatePriceFclMutation();

  const [chargesData, setChargesData] = useState(chargesdata);
  const [oneTimeCharges, setOneTimeCharges] = useState<any>([]);

  const [isEditMode, setIsEditMode] = useState(false);

  const isValidCharges = useValidCharges({ ...chargesData, oneTimeCharges });

  const fclLoading = useAppSelector(fclLoadingSelector);
  const sortBy = useAppSelector(sortBySelector);

  const [activeKey, setActiveKey] = useState<any>("");
  const { getQueryParam } = useQueryParams();
  const mainQuotationId = getQueryParam("id");

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { filteredQuotations, selectedQuotations, data } =
    useAppSelector(quotationsSelector);

  const onCheckboxChange: CheckboxProps["onChange"] = (id) => {
    const result = [...selectedQuotations];
    const index = result.indexOf(id);
    if (index > -1) {
      result.splice(index, 1);
    } else {
      result.push(id);
    }
    dispatch(setSelectedQuotations(result));
  };

  const requestQuote = (id: number, quotationId: string) => {
    navigate(`/confirm-quotation/${id}?quoteId=${quotationId}`);
  };

  const handleUpdateChargesFcl = (initialCharges: any) => {
    // return;
    updatePriceFcl({
      quotation_id: activeKey,
      chargesData: {
        ...chargesData,
        one_time_charges: oneTimeCharges,
      },
      customer_id: data?.customer_id,
    })
      .unwrap()
      .then((res) => {
        dispatch(
          updateQuotationCharges({
            subQuotationId: activeKey,
            chargesData: res?.data,
          }),
        );

        showToast(TOASTR_TYPES.SUCCESS, res?.message);
        setIsEditMode(false);
      })
      .catch((err) => {
        showToast(TOASTR_TYPES.ERROR, err?.data?.message);
        // setChargesData(initialCharges)
        console.log(err);
      });
  };

  useEffect(() => {
    dispatch(setSortBy(""));
    return () => {
      dispatch(setSelectedQuotations([]));
      dispatch(setSortBy(""));
    };
  }, []);

  const handleSortChange = (value: SORT_BY) => {
    dispatch(setSortBy(value));
    const results = sortByQuotations(filteredQuotations, value);
    dispatch(setFilteredQuotations(results));
  };

  return (
    <>
      <div className="flex justify-between py-10 pt-20">
        <label className="m-0 w-2/5 py-6 pr-5 text-slate-500">
          Showing {filteredQuotations?.length} results
        </label>
        <div className="flex">
          <div className="mr-5 w-full py-6 text-end ">Sort by:</div>
          <div className="w-[200px]">
            <Select
              onChange={handleSortChange}
              placeholder="Select"
              className="w-full"
              options={[
                { label: "Price", value: "price" },
                { label: "Transit time", value: "transit_time" },
                { label: "Cargo Ready Date", value: "date" },
              ]}
              popupClassName="!w-[165px]"
              value={sortBy || null}
            />
          </div>
        </div>
      </div>
      <hr />

      <div className="py-10"></div>

      <div className="overflow-x-auto">
        {/* <div className="mb-80 min-w-[1140px]"> */}
        <div className=" min-w-[1140px]">
          {filteredQuotations?.map((quotation: any) => {
            const isSelected = selectedQuotations?.includes(quotation.id);
            return (
              <div
                key={quotation?.id}
                className={`relative mb-30 flex flex-col rounded-[10px] border-primary-100  ${isSelected ? "bg-primary-100" : "bg-primary-50"} px-24 pt-8 shadow-card-shadow`}
              >
                <div className="absolute left-[12px] top-[6px]">
                  <Checkbox
                    checked={selectedQuotations?.includes(quotation.id)}
                    onChange={() => onCheckboxChange(quotation.id)}
                  ></Checkbox>
                </div>
                <div className="ml-8 flex flex-col border-b border-[#AFB6D1] ">
                  <table className="bodernon w-full">
                    <thead>
                      <tr className="mb-3 border-b border-[#AFB6D1] pb-8 text-left">
                        <th className="px-4 py-4 text-14 font-normal leading-20 text-primary-600">
                          Carrier
                        </th>
                        <th
                          className={`px-4 py-4 text-center text-14 font-normal leading-20 text-primary-600`}
                        >
                          Route
                        </th>
                        <th
                          className={`px-4 py-4 text-center text-14 font-normal leading-20 text-primary-600`}
                        >
                          All in rate
                        </th>
                        <th className="px-4 py-4 text-left text-14 font-normal leading-20 text-primary-600">
                          <div className="min-w-[100px]">Total Price</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="">
                        <td className="px-4 py-2">
                          <CarrierFclComponent
                            carrierCode={quotation?.carrier_code}
                          />
                        </td>
                        <td className="px-4 py-8">
                          <QuotationRoutesFcl
                            date=""
                            startDate={quotation?.date}
                            endDate={quotation?.delivery_date}
                            mode="Air"
                            isStop={false}
                            transit="Direct"
                            transitTime={quotation?.transit_time}
                            secondMode="Test"
                            transhipMentCount={
                              quotation?.service_type == "fcl"
                                ? getTransShipmentCount(quotation)
                                : 1
                            }
                            data={data}
                          />
                        </td>
                        <td
                          className="px-4 py-2"
                          style={{
                            width:
                              quotation?.categorised_charges?.columns?.length >
                              1
                                ? "320px"
                                : "auto",
                          }}
                        >
                          <div
                            className="relative flex h-auto  items-center justify-center  rounded-lg"
                            style={{
                              maxWidth:
                                quotation?.categorised_charges?.columns
                                  ?.length > 1
                                  ? "320px"
                                  : "auto",
                            }}
                          >
                            <AllInRateComponent
                              data={quotation?.categorised_charges?.columns?.filter(
                                (column: any) => column.sub_total != 0,
                              )}
                            />
                          </div>
                        </td>
                        <td className="w-[100px] px-4 py-2">
                          <p className="text-17 whitespace-nowrap text-right font-normal leading-32 text-gray-600">
                            EUR{" "}
                            {calculateFinalTotalPrice({
                              charges: quotation?.categorised_charges?.charges,
                              columns: quotation?.categorised_charges?.columns,
                              oneTimeCharges:
                                quotation?.categorised_charges
                                  ?.one_time_charges,
                            })}
                          </p>
                          {!isAdmin && (
                            <div className="flex justify-end">
                              <CustomButton
                                variant="primary"
                                className="mt-2 w-full max-w-[120px] rounded-lg !bg-orange-600 text-14 font-semibold hover:!bg-orange-500"
                                disabled={false}
                                title="Coming soon"
                                onClick={() =>
                                  requestQuote(quotation.id, mainQuotationId)
                                }
                              >
                                Book Now
                              </CustomButton>
                            </div>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div>
                  <Collapse
                    destroyInactivePanel={true}
                    activeKey={[activeKey]}
                    items={[
                      {
                        key: quotation?.id.toString(),
                        label: (
                          <div className="flex items-center justify-center">
                            <div className="expand-text text-12  font-semibold leading-18 text-primary-400">
                              Expand for price breakdown and timeline
                            </div>
                            <div className="shrink-back-text text-12 font-semibold leading-18 text-primary-400">
                              Shrink Back
                            </div>
                            <div className="icon">
                              <Icon.McsIcCollapseDown />
                            </div>
                          </div>
                        ),
                        children: (
                          <div
                            className={`mb-10 flex flex-col px-10 ${isSelected ? "bg-primary-100" : ""}`}
                          >
                            <ChargesTableFcl
                              initialCharges={addEditableFields(
                                quotation?.categorised_charges,
                              )}
                              initialOneTimeCharges={getInitialOneTimeCharges(
                                quotation?.categorised_charges
                                  ?.one_time_charges,
                              )}
                              chargesData={addEditableFields(chargesData)}
                              oneTimeCharges={oneTimeCharges}
                              setOneTimeCharges={setOneTimeCharges}
                              setChargesData={setChargesData}
                              isValidCharges={isValidCharges}
                              showEditButton={isAdmin}
                              quotation={quotation}
                              stickyColClassName={
                                isSelected ? "bg-primary-100" : "bg-primary-50"
                              }
                              handleUpdateChargesFcl={handleUpdateChargesFcl}
                              isEditMode={isEditMode}
                              setIsEditMode={setIsEditMode}
                            />
                          </div>
                        ),
                      },
                    ]}
                    className="custom-collapse"
                    onChange={(key: any) => {
                      console.log(key, "key>>>>>>>>");

                      if (key) {
                        setChargesData(
                          addEditableFields(quotation?.categorised_charges),
                        );
                        setOneTimeCharges(
                          getInitialOneTimeCharges(
                            quotation?.categorised_charges?.one_time_charges,
                          ),
                        );
                      }
                      setActiveKey(key[1]);
                      setIsEditMode(false);
                    }}
                  />
                </div>
                <CustomModal
                  title="Port Remarks"
                  isOpen={false}
                  toggle={() => {
                    console.log("hi");
                  }}
                  maskClosable={true}
                  icon={<Icon.McsIcChevronBack />}
                  width={955}
                  innerClass="custom-remarks-modal"
                >
                  <div className="flex flex-col gap-2">
                    <p className="text-12 font-normal leading-18 text-primary-400">
                      BRSSZ
                    </p>
                    <ul className="modal-list">
                      <li>Fumigation required for all wooden packages.</li>
                      <li>MANIFEST CLOSING 1 DAY BEFORE SAILING</li>
                      <li>Original B/L needed.</li>
                      <li>
                        B/L must mention ncm code, please advise before vessel
                        departing.
                      </li>
                      <li>Customs Clearance at Port of Entry by Consignee.</li>
                      <li>CNPJ number of consignee is mandatory.</li>
                      <li>Oceanfreight to be show on HBL.</li>
                      <li>
                        DG cargo : For all hazardous cargo MSDS required prior
                        shipping and subject approval carriers/agent approval.
                        (Imo 2 is not accepted)
                      </li>
                      <li>
                        Manifest Correction Eur 250,* + all additional charges
                        caused by correction
                      </li>
                      <li>
                        Personal effects / Household goods : to Brasil not
                        acceptable
                      </li>
                      <li>
                        Marks and Numbers: All goods must have marks and
                        sequential identification number on the total of
                        packages contemplated by document shipments.
                      </li>
                    </ul>
                  </div>
                </CustomModal>
              </div>
            );
          })}
        </div>
      </div>

      {fclLoading && (
        <div className="flex flex-col gap-y-[18px]">
          <CardLoader />
          <CardLoader />
          <CardLoader />
        </div>
      )}

      <QuotationSelected />
    </>
  );
};

export default QuotationResultsCardFcl;
