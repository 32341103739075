import React, { useState, useEffect, useMemo } from "react";
import QuotationTabs from "./elements/quotation-tabs";
import { IBreadcrumbItem, TITLES } from "src/types";
import CargoShipperSuccessFcl from "../../../components/organisms/cargo-shipper-success-fcl";
import CustomerPanelLayout from "src/components/layouts/customer-panel-layout";
import SRCustomerPreview from "src/components/layouts/sr-customerpreview";

import { useQuotationsContext } from "src/context/quotations-context";
import SRPreviewFcl from "src/components/layouts/sr-preview-fcl";
import { useQuotationsContextFcl } from "src/context/quotations-context-fcl";
import CargoShipperSuccess from "../../../components/organisms/cargo-shipper-success";

export const Quotations = () => {
  const params = new URLSearchParams(location.search);
  const tab = useMemo(() => params.get("tab"), [location]);
  const id = useMemo(() => params.get("id"), [location]);

  const { formikQuotation } = useQuotationsContext();
  const { formikQuotationFcl } = useQuotationsContextFcl();
  console.log(formikQuotation?.values, "values.>>");

  const breadcrumbsData: IBreadcrumbItem[] = [
    {
      title: tab ?? "Fill Details",
    },
  ];

  if (id) {
    breadcrumbsData.push({
      title: "Fill Details",
    });
  }

  const [data, setData] = useState(breadcrumbsData);

  const APP_TITLE = process.env.REACT_APP_TITLE;
  const TAB_TITLE = TITLES.QUOTATIONS;

  const [success, setSuccess] = useState(false);

  useEffect(() => {
    document.title = TAB_TITLE + " | " + APP_TITLE;
  }, [data]);

  useEffect(() => {
    document.title = TAB_TITLE + " | " + APP_TITLE;
  }, []);

  const tabChanged = (title: any) => {
    setData([{ title: title || "Quotations" }]);
  };

  return (
    <CustomerPanelLayout breadcrumbsData={data}>
      <>
        {formikQuotationFcl?.isSubmitting && (
          <CargoShipperSuccessFcl toggle={setSuccess} />
        )}

        {formikQuotation?.isSubmitting && (
          <CargoShipperSuccess toggle={setSuccess} />
        )}

        <div
          className={
            formikQuotationFcl?.isSubmitting || formikQuotation?.isSubmitting
              ? "hidden"
              : ""
          }
        >
          <QuotationTabs clicked={success} tabChanged={tabChanged} />
          {formikQuotation?.values?.service_type == "lcl" && (
            <SRCustomerPreview onSuccess={(val) => setSuccess(val)} />
          )}
          {formikQuotation?.values?.service_type == "fcl" && <SRPreviewFcl />}
        </div>
      </>
    </CustomerPanelLayout>
  );
};
