import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import CustomLink from "src/components/atoms/custom-link";
import { isAdmin } from "src/helpers";
import QuotationResultCardFcl from "src/components/molecules/fcl/quotations-result-card-fcl";

import {
  useRemoveQuotationMutation,
  useGetQuotationDetailsMutation,
  useProcessIndividualQuotationMutation,
} from "src/services/api-service/quotations";
import { useAppDispatch, useAppSelector } from "src/store/hook";
import { setLoadingFcl } from "src/store/features/quotations-fcl";
import {
  carriersSelector,
  quotationsSelector,
} from "src/store/features/quotations/selectors";
import { SERVICE_TYPE } from "src/types";
import QuotationResultFiltersFcl from "src/components/molecules/fcl/quotations-result-filters-fcl";
import { resetQuotations } from "src/store/features/quotations";
import useQueryParams from "src/hooks/useQueryParams";
import { showToast } from "src/utils";
import { TOASTR_TYPES } from "src/components/atoms/toast-container/types";
import { delay } from "lodash";

const QuotationResultsFclComponent = ({ href }: { href: string }) => {
  const { pathname } = useLocation();

  const { getQueryParam } = useQueryParams();
  const id = getQueryParam("id");
  const process = getQueryParam("process");

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const dispatch = useAppDispatch();

  const [processIndividualQuotation] = useProcessIndividualQuotationMutation();
  const [removeQuotation] = useRemoveQuotationMutation();

  const carriers = useAppSelector(carriersSelector);

  const [getDetailsMutation] = useGetQuotationDetailsMutation<any>();

  const isFirstRender = useRef(true);
  const [isComponentMounted, setIsComponentMounted] = useState(true); // Use state instead of ref

  // Function to get quotation details (only if the component is still mounted)
  const getDetails = () => {
    if (!id || !isComponentMounted) return; // Prevent execution if unmounted

    getDetailsMutation({ id, quotationType: SERVICE_TYPE.FCL })
      .unwrap()
      .catch((err) => {
        if (isComponentMounted) {
          console.error("Error fetching details:", err);
        }
      })
      .finally(() => {
        process == "false" && dispatch(setLoadingFcl(false));
      });
  };

  useEffect(() => {
    dispatch(resetQuotations());
    setIsComponentMounted(true); // Mark as mounted

    return () => {
      setIsComponentMounted(false); // Mark as unmounted
    };
  }, []);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (!carriers.length || !id) return;

    const carriersList = carriers
      .filter((carrier: any) => carrier.active_status)
      .map((carrier: any) => carrier.ratefetcher_code);

    let payload: any = sessionStorage.getItem("payload") || "{}";
    payload = JSON.parse(payload);

    let completedRequests = 0;
    dispatch(setLoadingFcl(true));
    const fetchQuotations = async () => {
      let foundResults = false; // Track if any request returns a success response

      await Promise.allSettled(
        carriersList.map((carrier: any) =>
          processIndividualQuotation({
            ...payload,
            main_quotation_id: id,
            carrier,
          })
            .unwrap()
            .then((res) => {
              if (isComponentMounted && res?.success) {
                console.log(res, "Response received");
                foundResults = true; // Mark as true if any request succeeds
                getDetails();
              }
            })
            .catch((err) => {
              if (isComponentMounted) {
                console.error(err, "Error occurred");
              }
            })
            .finally(() => {
              completedRequests++;
              if (
                completedRequests === carriersList.length &&
                isComponentMounted
              ) {
                dispatch(setLoadingFcl(false));

                if (!foundResults) {
                  navigate(href);

                  // Remove 'id' from URL after navigation
                  setTimeout(() => {
                    const newUrl = new URL(window.location.href);
                    newUrl.searchParams.delete("id");
                    window.history.replaceState({}, "", newUrl);
                    removeQuotation({ id });
                  }, 2000);

                  showToast(
                    TOASTR_TYPES.ERROR,
                    "No Offers available for specified criteria.",
                  );
                }
              }
            }),
        ),
      );
    };

    if (process == "true") {
      fetchQuotations();
    } else {
      getDetails();
    }
  }, [carriers, id]);

  return (
    <>
      <CustomLink wrapperClassName="!pl-0" href={href}>
        Back to edit search
      </CustomLink>
      <QuotationResultFiltersFcl />
      <QuotationResultCardFcl isAdmin={isAdmin(pathname)} />
    </>
  );
};

export default QuotationResultsFclComponent;
