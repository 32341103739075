import React from "react";
import { IFormHeader } from "./form-header.types";
import { CONSTANTS } from "src/constants";

const FormHeader = ({ title, description }: IFormHeader) => {
  return (
    <div className="mb-32 ">
      <div className="mb-32 flex">
        <a href={CONSTANTS.WEBSITE_LINK} target="_black">
          <img
            className="w-full "
            src="/assets/images/mcs-logo.svg"
            alt="mcs"
            title="mcs"
          />
        </a>
        <div className="px-32">
          <div className="h-full border-l border-gray-500-base"></div>
        </div>
        <a href={CONSTANTS.WEBSITE_LINK} target="_black">
          <img
            className="h-[50px] w-full"
            src="/assets/images/mcs-marinair-logo-1.svg"
            alt="Marinair"
            title="Marinair"
          />
        </a>
      </div>
      <h1 className="mb-12 text-32 font-semibold leading-40 tracking-0.72 text-primary-500-base sm:text-36 sm:leading-44">
        {title}
      </h1>
      <p className="text-14 font-normal leading-20 text-gray-500-base sm:text-16 sm:leading-24">
        {description}
      </p>
    </div>
  );
};

export default FormHeader;
