export const CONSTANTS: any = {
  COOKIE_JWT: "marineair-jwt",
  TOKEN: "token",
  BASE_URL: {
    ADMIN: "https://backend.marineair.test.crebos.online",
    WEBSITE: "http://localhost",
  },
  LOGIN_TYPE: {
    ADMIN: 1,
    WEBSITE: 2,
  },
  VALIDATIONS: {
    EMAIL: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\s*$/,
    URL: /^(https?:\/\/)?([A-Za-z0-9-]+\.){1,}[A-Za-z]{2,}(:[0-9]+)?(\/[^\s]*)?$/,
  },
  MEASUREMENT_UNITS: {
    IMPERIAL: {
      NAME: "IMPERIAL",
      LABEL: "in/lb",
    },
    METRIC: {
      NAME: "METRIC",
      LABEL: "cm/kg",
    },
  },
  MEASUREMENTS: {
    HEIGHT: "HEIGHT",
    WEIGHT: "WEIGHT",
  },
  UNITS: {
    LENGTH: {
      IMPERIAL: "in",
      METRIC: "cm",
    },
    WEIGHT: {
      IMPERIAL: "lb",
      METRIC: "kg",
    },
    Volume: {
      IMPERIAL: "ft3",
      METRIC: "M3",
    },
  },
  DEFAULT_CUSTOMER_EMAIL: "customer@marinair.com",
  FCL: {
    TERMS_OPTIONS: [
      {
        label: "Door to Port", // 2
        value: "1",
        show: false,
      },
      {
        label: "Port to Port", // 2
        value: "2",
        show: true,
      },
      {
        label: "Door to Door", // 3
        value: "3",
        show: false,
      },
      {
        label: "Port to Door", // 2
        value: "4",
        show: false,
      },
    ],
    CONTAINER_TYPES: [],
  },
  WEBSITE_LINK: "https://www.marinair.gr",
};
