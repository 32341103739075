import React, { useEffect, useState } from "react";
import CustomLink from "src/components/atoms/custom-link";
import QuotationsResultFilters from "../../../../pages/quotation-results/elements/quotation-results-filters";
import QuotationResultsCard from "../../../../pages/quotation-results/elements/quotation-results-card";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setLoadingFcl } from "src/store/features/quotations-fcl";
import { useGetQuotationDetailsMutation } from "src/services/api-service/quotations";
import { useDispatch } from "react-redux";
import { useQuotationsContext } from "src/context/quotations-context";
import { setSelectedQuotations } from "src/store/features/quotations";
import { SERVICE_TYPE } from "src/types";

const QuotationsResultsLcl = ({ href }: { href: string }) => {
  const [success, setSuccess] = useState(false);

  const navigate = useNavigate();
  const [url] = useSearchParams();
  const id: string = url?.get("id") ?? "";

  const dispatch = useDispatch();
  const [reload] = useState(false);

  const { formikQuotation } = useQuotationsContext();

  const [getDetailsMutation] = useGetQuotationDetailsMutation<any>();

  const getDetails = () => {
    dispatch(setLoadingFcl(true));
    id &&
      getDetailsMutation({ id, quotationType: SERVICE_TYPE.FCL })
        .unwrap()
        .finally(() => {
          dispatch(setLoadingFcl(false));
        });
  };

  useEffect(() => {
    getDetails();
  }, [id]);

  useEffect(() => {
    if (reload) getDetails();
  }, [reload]);

  useEffect(() => {
    console.log(formikQuotation?.values?.quotation_type);
    if (formikQuotation?.values?.quotation_type === "custom") {
      formikQuotation.setFieldValue("quotation_type", "internal");
      navigate("/admin/quotations?tab=Generate%20Quotations&id=" + id);
    } else {
      getDetails();
    }
  }, [success]);
  useEffect(() => {
    dispatch(setSelectedQuotations([]));
  }, []);

  return (
    <>
      <CustomLink href={href}>Back to edit search</CustomLink>
      <QuotationsResultFilters getDetails={getDetails} isCustomer={false} />
      <QuotationResultsCard />
    </>
  );
};

export default QuotationsResultsLcl;
