import React from "react";

const McsIcSync = () => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Sync">
        <path
          id="Vector"
          d="M9.99992 3.33203V0.832031L6.66659 4.16536L9.99992 7.4987V4.9987C12.7583 4.9987 14.9999 7.24036 14.9999 9.9987C14.9999 10.8404 14.7916 11.6404 14.4166 12.332L15.6333 13.5487C16.2833 12.5237 16.6666 11.307 16.6666 9.9987C16.6666 6.31536 13.6833 3.33203 9.99992 3.33203ZM9.99992 14.9987C7.24159 14.9987 4.99992 12.757 4.99992 9.9987C4.99992 9.15703 5.20825 8.35703 5.58325 7.66536L4.36659 6.4487C3.71659 7.4737 3.33325 8.69036 3.33325 9.9987C3.33325 13.682 6.31659 16.6654 9.99992 16.6654V19.1654L13.3333 15.832L9.99992 12.4987V14.9987Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default McsIcSync;
