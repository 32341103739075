import React from "react";
import { Select } from "antd";
import { showToast } from "src/utils";
import { TOASTR_TYPES } from "src/components/atoms/toast-container/types";
import { Icon } from "src/components/atoms/icons";
import { useUpdateCarrierStatusMutation } from "src/services/api-service/carriers";

interface StatusEditorProps {
  value: any;
  id: any;
}

const CarrierStatusComponent = ({ value, id }: StatusEditorProps) => {
  const [updateCarrierStatus] = useUpdateCarrierStatusMutation();

  const updateStatusById = (status: string) => {
    updateCarrierStatus({
      id,
      status,
    })
      .unwrap()
      .then((data: any) => {
        showToast(
          TOASTR_TYPES.SUCCESS,
          data?.message || data?.data?.message || data?.original?.message,
        );
      });
  };

  const handleChange = (status: string) => {
    updateStatusById(status);
  };

  const statusArray = [
    {
      value: true,
      label: (
        <div className="flex justify-center">
          <div className="rounded-2xl bg-success-50 px-8 py-2 text-12 font-medium leading-18 text-success-700">
            Active
          </div>
        </div>
      ),
    },
    {
      value: false,
      label: (
        <div className="flex justify-center">
          <div className="rounded-2xl bg-error-danger-50 px-8 py-2 text-12 font-medium leading-18 text-error-danger-700">
            Inactive
          </div>
        </div>
      ),
    },
  ];

  const defaultOptionValues = statusArray;

  return (
    <Select
      defaultValue={value}
      className="select-status ml-[-5px] w-[120px]"
      popupClassName="status-tag-dropdown"
      onChange={handleChange}
      options={defaultOptionValues}
      suffixIcon={<Icon.McsIcBrandColorChevronDown />}
    />
  );
};

export default CarrierStatusComponent;
