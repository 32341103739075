import React from "react";
import { Card, Collapse, Steps } from "antd";
import { Icon } from "src/components/atoms/icons";
import {
  calculateFinalTotalPrice,
  getTotalByChargeIdForBooking,
  getTotalOfSubChargeDetailForBooking,
  mergeCharges,
} from "src/helpers";

const QuotationCharges = ({ data }: { data: any }) => {
  console.log(data?.categorised_charges, "data>>>>>");

  if (data?.service_type == "lcl") {
    return (
      <Card size="small" className="bg-primary-50 p-0">
        <CollpseFee
          title="Pre - Carriage"
          feeArray={data?.priceBreakUp?.preCarriage}
          isBorder={false}
          serviceType={data?.service_type}
        />
        <CollpseFee
          title="Origin Charges"
          feeArray={data?.priceBreakUp?.origin}
          serviceType={data?.service_type}
        />
        <CollpseFee
          title="Ocean Freight"
          feeArray={data?.priceBreakUp?.oceanFreight}
          serviceType={data?.service_type}
        />
        <div className="flex items-center justify-between border-t border-[#475467]  px-12 pt-8">
          <span className="text-sm font-medium">Billing Price</span>
          <span className="text-lg font-semibold text-primary-500-base">
            EUR {data?.price}
          </span>
        </div>
      </Card>
    );
  } else {
    return (
      <Card size="small" className="bg-primary-50 p-0">
        {mergeCharges(data?.categorised_charges)?.charges?.map(
          (charge: any, idx: any) => {
            return (
              <CollpseFee
                key={charge?.id}
                title={charge?.category}
                feeArray={charge?.details}
                isBorder={false}
                serviceType={data?.service_type}
                charges={mergeCharges(data?.categorised_charges)}
                total={getTotalByChargeIdForBooking(
                  charge?.id,
                  idx,
                  mergeCharges(data?.categorised_charges),
                )}
              />
            );
          },
        )}

        <div className="flex items-center justify-between border-t border-[#475467]  px-12 pt-8">
          <span className="text-sm font-medium">Billing Price</span>
          <span className="text-lg font-semibold text-primary-500-base">
            EUR{" "}
            {data?.categorised_charges &&
              calculateFinalTotalPrice({
                ...data?.categorised_charges,
                oneTimeCharges: data?.categorised_charges?.one_time_charges,
              })}
          </span>
        </div>
      </Card>
    );
  }
};

export default QuotationCharges;

const CollpseFee = ({
  title,
  feeArray,
  isBorder = true,
  serviceType,
  total,
  charges,
}: {
  title: string;
  feeArray: any;
  isBorder?: boolean;
  serviceType: string;
  total?: any;
  charges?: any;
}) => {
  if (serviceType == "lcl") {
    const totalFee = feeArray?.reduce(
      (accumulator: number, currentValue: any) =>
        accumulator + Number(currentValue.charges.replace(/,/g, "")),
      0,
    );
    return (
      <Collapse
        collapsible={feeArray?.length < 1 ? "disabled" : "header"}
        bordered={false}
        size="small"
        expandIcon={({ isActive }) => {
          return (
            <>
              {isActive ? <Icon.McsIcMinusCircle /> : <Icon.McsIcPlusCircle />}
            </>
          );
        }}
        className="main-confirm-collapse"
        items={[
          {
            key: "1",
            label: (
              <div className="flex w-full select-none !justify-between font-medium">
                <span>{title}</span>
                <span
                  className={`font-medium ${feeArray?.length < 1 ? "" : "text-primary-500-base"}`}
                >
                  EUR {parseFloat(totalFee).toFixed(2)}
                </span>
              </div>
            ),
            headerClass: `w-full ${isBorder ? "border-t border-[#AFB6D1]" : ""}`,
            children: (
              <>
                {feeArray?.map((pc: any, i: any) => (
                  <div
                    key={`${pc.type}-${i}`}
                    className={`mx-16 mt-4 grid ${serviceType == "lcl" ? "grid-cols-3" : "grid-cols-2"} pb-6 text-xs`}
                  >
                    <span className="line-clamp-1 text-ellipsis">
                      {pc.type}
                    </span>
                    {serviceType == "lcl" && (
                      <span className="line-clamp-1 text-ellipsis">
                        {pc.qty}
                      </span>
                    )}
                    <span className="text-right">EUR {pc.charges}</span>
                  </div>
                ))}
              </>
            ),
          },
        ]}
      />
    );
  } else {
    // FCL
    return (
      <Collapse
        collapsible={feeArray?.length < 1 ? "disabled" : "header"}
        bordered={false}
        size="small"
        expandIcon={({ isActive }) => {
          return (
            <>
              {isActive ? <Icon.McsIcMinusCircle /> : <Icon.McsIcPlusCircle />}
            </>
          );
        }}
        className="main-confirm-collapse"
        items={[
          {
            key: "1",
            label: (
              <div className="flex w-full select-none !justify-between font-medium">
                <span>{title}</span>
                <span
                  className={`font-medium ${feeArray?.length < 1 ? "" : "text-primary-500-base"}`}
                >
                  EUR {total}
                </span>
              </div>
            ),
            headerClass: `w-full ${isBorder ? "border-t border-[#AFB6D1]" : ""}`,
            children: (
              <>
                {feeArray?.map((item: any) => (
                  <div
                    key={`${item?.name}`}
                    className={`mx-16 mt-4 grid grid-cols-2 pb-6 text-xs`}
                  >
                    <span className="line-clamp-1 text-ellipsis">
                      {item?.charge_description || item?.name}
                    </span>
                    <span className="text-right">
                      {" "}
                      EUR {getTotalOfSubChargeDetailForBooking(item, charges)}
                    </span>
                  </div>
                ))}
              </>
            ),
          },
        ]}
      />
    );
  }
};
