import React from "react";
import { Checkbox, Skeleton } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";

import {
  setFilteredQuotations,
  setQuotationFilters,
  setSelectedQuotations,
} from "src/store/features/quotations";
import {
  quotationsSelector,
  sortBySelector,
} from "src/store/features/quotations/selectors";
import { useAppDispatch, useAppSelector } from "src/store/hook";
import { SIDEBAR_FILTER_TYPES_FCL } from "./sidebar-filters.data";
import { sortByQuotations } from "src/helpers";

const SidebarFiltersComponent = ({ title, values, filterType }: any) => {
  const quotationsData = useAppSelector(quotationsSelector);
  const sortBy = useAppSelector(sortBySelector);

  const dispatch = useAppDispatch();

  const handleChange = (e: CheckboxChangeEvent) => {
    const value: string = e.target.value;
    const checked: boolean = e.target.checked;
    const filters: any = JSON.parse(
      JSON.stringify(quotationsData.quotationFilters),
    );

    if (checked) {
      filters[filterType].push(value); // Add the value to filters if checked
    } else {
      const index = filters[filterType].indexOf(value);
      if (index !== -1) {
        filters[filterType].splice(index, 1); // Remove the value from filters if unchecked
      }
    }

    let filteredQuotations = quotationsData?.quotations
      ? [...quotationsData.quotations]
      : [];

    filteredQuotations = quotationsData.quotations?.filter((quotation: any) =>
      filters[SIDEBAR_FILTER_TYPES_FCL.CARRIERS].length > 0
        ? filters[SIDEBAR_FILTER_TYPES_FCL.CARRIERS].some(
            (carrierStr: string) =>
              carrierStr
                .split(",")
                .map((c) => c.trim()) // Ensure no extra spaces
                .includes(quotation.carrier_code),
          )
        : true && filters[SIDEBAR_FILTER_TYPES_FCL.SERVICES].length > 0
          ? filters[SIDEBAR_FILTER_TYPES_FCL.SERVICES].includes(
              quotationsData?.data?.terms?.toString(),
            )
          : true && filters[SIDEBAR_FILTER_TYPES_FCL.SHIPPING_MODE].length > 0
            ? filters[SIDEBAR_FILTER_TYPES_FCL.SHIPPING_MODE].includes(
                quotation.mode_of_transport,
              )
            : true,
    );

    console.log(filters, "filters >>>>");

    filteredQuotations = sortByQuotations(filteredQuotations, sortBy);

    dispatch(setSelectedQuotations([]));
    dispatch(setFilteredQuotations(filteredQuotations));
    dispatch(setQuotationFilters(filters));
  };

  return (
    <>
      <div className="flex flex-col gap-y-3  px-12 py-12">
        <h4 className="text-16 font-semibold leading-24 text-primary-600">
          {title}
        </h4>
        {values?.map((e: any, idx: any) => {
          return !e ? (
            <Skeleton.Button
              key={e?.label}
              size="small"
              block={true}
              active={true}
            />
          ) : (
            <div
              className={`flex items-start gap-x-3 ${e.disabled ? "opacity-[0.60]" : ""}`}
              key={e.value}
            >
              <Checkbox
                id={`label-${e.value}`}
                onChange={handleChange}
                value={e.value}
                checked={
                  quotationsData.quotationFilters[filterType].includes(
                    e.value,
                  ) || e.checked
                }
                className={` ${e.disabled ? "pointer-events-none" : ""}`}
              />
              <label
                htmlFor={`label-${e.value}`}
                className={`${e.disabled ? "pointer-events-none cursor-not-allowed" : "cursor-pointer"}  mt-1 text-14 font-medium leading-20 text-primary-600`}
              >
                {e.label}
              </label>
            </div>
          );
        })}
      </div>
      <hr className="h-[1px] w-full bg-primary-100"></hr>
    </>
  );
};

export default SidebarFiltersComponent;
