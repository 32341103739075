import React from "react";

export const CARRIERS: any = [
  {
    carrierRatesPlatformId: 3,
    name: "Maersk Spot",
    code: "MSK1",
    onlineQuoteLink: "https://www.maersk.com/instantPrice/",
    authenticationType: "Token",
    status: "Ok",
    type: "Fcl",
    enabled: true,
    carrierId: 18,
    inlandCarrierId: null,
    creationDate: "2020-10-17T14:40:00.7032974",
    code2: "MSK",
    image: "maersk.svg",
  },
  {
    carrierRatesPlatformId: 5,
    name: "MSC MyMSC",
    code: "MSC",
    onlineQuoteLink: "https://www.mymsc.com/mymsc/InstantQuote/Home",
    authenticationType: "Credentials",
    status: "Ok",
    type: "Fcl",
    enabled: true,
    carrierId: 22,
    inlandCarrierId: null,
    creationDate: "2020-10-17T14:40:00.7032986",
    code2: "MSC",
    image: "msc.svg",
  },
  {
    carrierRatesPlatformId: 8,
    name: "Evergreen GreenX",
    code: "EMC2",
    onlineQuoteLink: "https://portal.greenxtrade.com/#/quotes",
    authenticationType: "Token",
    status: "Ok",
    type: "Fcl",
    enabled: true,
    carrierId: 11,
    inlandCarrierId: null,
    creationDate: "2020-12-15T11:01:32.5503013",
    code2: "EMC",
    image: "green-x.svg",
  },
  {
    carrierRatesPlatformId: 11,
    name: "ONE Quote",
    code: "ONE",
    onlineQuoteLink: "https://ecomm.one-line.com/one-ecom/prices/one-quote",
    authenticationType: "Credentials",
    status: "Ok",
    type: "Fcl",
    enabled: true,
    carrierId: 105,
    inlandCarrierId: null,
    creationDate: "2021-05-24T06:45:55.5383395",
    code2: "ONE",
    image: "one.svg",
    width: 57,
  },
  {
    carrierRatesPlatformId: 12,
    name: "ZIM eZquotes",
    code: "ZIM",
    onlineQuoteLink: "https://www.ezquote.zim.com/quotes/",
    authenticationType: "Credentials",
    status: "Ok",
    type: "Fcl",
    enabled: true,
    carrierId: 32,
    inlandCarrierId: null,
    creationDate: "2021-07-15T08:35:35.3278127",
    code2: "ZIM",
    image: "ezquote.jpeg",
  },
  {
    carrierRatesPlatformId: 13,
    name: "Cosco SynConHub",
    code: "CCL",
    onlineQuoteLink: "https://synconhub.coscoshipping.com/prebooking",
    authenticationType: "Credentials",
    status: "Ok",
    type: "Fcl",
    enabled: true,
    carrierId: 8,
    inlandCarrierId: null,
    creationDate: "2021-09-02T06:42:03.784422",
    code2: "CCL",
    image: "cosco.svg",
  },
  {
    carrierRatesPlatformId: 16,
    name: "Hapag Lloyd Quick Quotes & Spot",
    code: "HPL2",
    onlineQuoteLink: "https://solutions.hapag-lloyd.com/quick-quotes/#/",
    authenticationType: "Credentials",
    status: "Ok",
    type: "Fcl",
    enabled: true,
    carrierId: 15,
    inlandCarrierId: null,
    creationDate: "2022-03-21T08:17:54.4400902",
    code2: "HPL",
    image: "hapag.svg",
  },
  {
    carrierRatesPlatformId: 20,
    name: "HMM HiQuotes",
    code: "HMM1",
    onlineQuoteLink: "https://www.hmm21.com/e-service/hiquote/quotation.do",
    authenticationType: "Credentials",
    status: "Ok",
    type: "Fcl",
    enabled: true,
    carrierId: 16,
    inlandCarrierId: null,
    creationDate: "2023-05-22T20:13:37.7243395",
    code2: "HMM",
    image: "hmm.svg",
  },
  {
    carrierRatesPlatformId: 21,
    name: "CMA CGM SpotOn",
    code: "CMA4",
    onlineQuoteLink: "https://www.cma-cgm.fr/ebusiness/pricing/instant-Quoting",
    authenticationType: "Token",
    status: "Ok",
    type: "Fcl",
    enabled: true,
    carrierId: 6,
    inlandCarrierId: null,
    creationDate: "2023-06-24T14:21:16.6237784",
    code2: "CMA",
    image: "cma.svg",
  },
];

const CarrierFclComponent = ({ carrierCode }: { carrierCode: string }) => {
  const logo = CARRIERS.find(
    (carrier: any) =>
      carrier.code == carrierCode || carrier.code2 == carrierCode,
  );

  return (
    <div className="flex min-w-[85px] max-w-[110px] flex-1 flex-col items-center justify-center text-center">
      {logo ? (
        <img
          className="mx-auto w-[74px]"
          src={`../../../assets/images/carriers/${logo?.image}`}
          alt={carrierCode}
          title="marinair-logo"
          style={{
            width: logo?.width ? `${logo?.width}px` : "85px",
          }}
        />
      ) : (
        <p className="text-16 font-semibold leading-24 text-gray-700">
          {carrierCode}
        </p>
      )}
    </div>
  );
};

export default CarrierFclComponent;
