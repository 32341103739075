import React, { useState } from "react";
import PanelLayout from "src/components/layouts/panel-layout";
import ContentWrapper from "src/components/layouts/content-wrapper";
import { IBreadcrumbItem, SERVICE_TYPE } from "src/types";
import CargoShipperSuccess from "../../components/organisms/cargo-shipper-success";
import QuotationResultsFcl from "../../components/molecules/fcl/quotation-results-fcl";
import QuotationsResultsLcl from "../../components/molecules/lcl/quotation-results-lcl";
import useQueryParams from "src/hooks/useQueryParams";

const QuotationsResultComponent = () => {
  const { getQueryParam } = useQueryParams();
  const serviceType: string = getQueryParam("serviceType");
  const id: string = getQueryParam("id");

  const [success, setSuccess] = useState(false);

  const breadcrumbsData: IBreadcrumbItem[] = [
    {
      title: "Generate Quotations",
      href: "/admin/quotations?tab=Generate%20Quotations",
    },
    {
      title: "Results",
    },
  ];

  const href = `/admin/quotations?tab=Generate%20Quotations&id=${id}`;

  return (
    <PanelLayout isResultsPage={true} breadcrumbsData={breadcrumbsData}>
      <>
        <div className={!success ? "hidden" : ""}>
          <CargoShipperSuccess toggle={setSuccess} />
        </div>
        <ContentWrapper>
          {serviceType == SERVICE_TYPE.LCL && (
            <QuotationsResultsLcl href={href} />
          )}
          {serviceType == SERVICE_TYPE.FCL && (
            <QuotationResultsFcl href={href} />
          )}
        </ContentWrapper>
      </>
    </PanelLayout>
  );
};

export default QuotationsResultComponent;
