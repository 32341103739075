import React from "react";
import Slider from "react-slick";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CARRIERS } from "src/components/molecules/fcl/carrier-fcl/carrier-fcl.component";

const CarriersSliderComponent = () => {
  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: false,
    arrows: false,
  };

  return (
    <div className="mb-[40px] flex w-full items-center justify-center">
      <Slider {...settings} className="h-10 w-[500px]">
        {CARRIERS.map((carrier: any) => (
          <img
            key={carrier.image}
            src={`/assets/images/carriers/${carrier.image}`}
            alt="carrier"
            className="mx-auto h-12"
          />
        ))}
      </Slider>
    </div>
  );
};

export default CarriersSliderComponent;
